import httpClient from "@/http-client";

export const get = (Options = {}) => {
    return httpClient
        .get("/api/att-devices", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/att-devices", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return httpClient
        .put(`/api/att-devices/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const destroy = id => {
    console.log(id);
    return httpClient
        .delete(`/api/att-devices/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const deviceType = (Option = {}) => {
    return httpClient
        .get("/api/att-devices/device-types", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export default { get, store, update, destroy, deviceType };
