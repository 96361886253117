import httpClient from "@/http-client";

export const get = (options = {}) => {
    return httpClient
        .get("/api/first-cycle-paids", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return httpClient
        .put(`/api/first-cycle-paids/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const remove = data => {
    return httpClient
      .post('/api/first-cycle-paids/removes', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data));
};

export default {get,update,remove}