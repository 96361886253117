import http from "@/http-client";

export const store = data => {
    return http
        .post("/api/systems", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const general = (Option = {}) => {
    return http
        .get("/api/systems/generals", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const repaymentItemPriority = (Option = {}) => {
    return http
        .get("/api/systems/repayment-item-priorities", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const overduePenalty = (Option = {}) => {
    return http
        .get("/api/systems/overdule-penalties", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export default {
    store,
    general,
    repaymentItemPriority,
    overduePenalty
};
