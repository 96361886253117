import branch from "@/api/corporate/branch";
import { baseState, baseMutations } from "@/store/mixins";

import { isEmpty } from "lodash";

export const state = {
    ...baseState,
    companies: [],
    ref_school_companies: [],
    provinces: [],
    currencies: [],
    employees: []
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_COMPANIES(state, data) {
        state.companies = data;
    },
    SET_REF_SCHOOL_COMPANIES(state, data) {
        state.ref_school_companies = data;
    },
    SET_CURRENCIES(state, data) {
        state.currencies = data;
    },
    SET_PROVINCES(state, data) {
        state.provinces = data;
    },
    SET_EMPLOYEES(state, data) {
        state.employees = data;
    }
};

export const actions = {
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return branch.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },

    store(context, data) {
        return branch.store(data);
    },

    async find(context, id) {
        if (isEmpty(context.state.edit_data)) {
            let response = await branch.find(id);
            context.commit("SET_EDIT_DATA", response.data);
        }
    },

    update(context, { id, data }) {
        return branch.update(id, data);
    },

    destroy(context, id) {
        return branch.destroy(id);
    },

    removeContract(context, contractId) {
        return branch.removeContract(contractId);
    },

    updateContract(context, { id, data }) {
        return branch.updateContract(id, data);
    },

    uploadLogo(context, data) {
        return branch.uploadLogo(data);
    },

    getLogo(context, branch_id) {
        return branch.getLogo(branch_id);
    },

    async getCompany(context) {
        let response = await branch.company();
        context.commit("SET_COMPANIES", response.data);
    },

    async getRefSchoolCompany(context) {
        let response = await branch.refSchoolCompany();
        context.commit("SET_REF_SCHOOL_COMPANIES", response.data);
    },

    async getCurrency(context, attributes = {}) {
        let response = await branch.currency({ params: attributes });
        context.commit("SET_CURRENCIES", response.data);
    },

    async getProvince(context) {
        let response = await branch.province();
        context.commit("SET_PROVINCES", response.data);
    },
    getDistrict(context, province_code) {
        if (!province_code) return;
        return branch.district(province_code);
    },
    getCommune(context, district_code) {
        if (!district_code) return;
        return branch.commune(district_code);
    },
    getVillage(context, commune_code) {
        if (!commune_code) return;
        return branch.village(commune_code);
    },
    rentalContract(context, data) {
        return branch.rentalContract(data);
    },
    removeFileUploaded(context, attributes) {
        return branch.removeFile({
            params: attributes
        });
    },
    async getEmployees(context, attributes = {}) {
        let response = await branch.employee({
            params: Object.assign({}, attributes)
        });
        context.commit("SET_EMPLOYEES", response.data);
    },
    exportExcel(context, data) {
        return branch.exportExcel(data)
    },
};
