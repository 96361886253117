import httpClient from "@/http-client";

export const get = (Options = {}) => {
    return httpClient
        .get("/api/coa-setups", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/coa-setups", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return httpClient
        .put(`/api/coa-setups/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const destroy = id => {
    return httpClient
        .delete(`/api/coa-setups/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const changeIsActive = (id, data) => {
    return httpClient
        .put(`/api/coa-setups/change-is-actives/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const coaCotegory = (Option = {}) => {
    return httpClient
        .get("/api/coa-setups/coa-categories", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const currency = (Option = {}) => {
    return httpClient
        .get("/api/coa-setups/currencies", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export default { get, store, update, destroy, changeIsActive, coaCotegory, currency };
