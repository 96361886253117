<template>
    <div>
        <div id="container">
            <i class="fas fa-circle-notch fa-spin fa-3x text-white" v-if="value"></i>
            <iframe
                id="pdf_iframe"
                ref="pdf_iframe"
                :src="src"
                v-if="!value"
            ></iframe>
        </div>
    </div>
</template>

<script>
export default {
    name: 'previewPdf',
    props: ['value', 'src'],
    data () {
        return {}
    }
}
</script>

<style scoped>
#container {
    width: 100%;
    height: 80vh;
    background: rgb(93, 94, 95);
    text-align: center;
    border: 2px solid #acacac;
}
#container iframe {
    width: 100%;
    height: 80vh;
}
#container i {
  margin-top: 39vh;
  color: white;
}
</style>
