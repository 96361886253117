import httpClient from '@/http-client'
export const get = (options = {}) => {
    return httpClient
        .get('/api/monthly-allowances', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const employeeProfile = (Option = {}) => {
    return httpClient
        .get('/api/monthly-allowances/employee-profiles', Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const payrollItemAllowance = (Option = {}) => {
    return httpClient
        .get('/api/monthly-allowances/payroll-item-allowances', Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const store = data => {
    return httpClient
        .post('/api/monthly-allowances', data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const update = (id, data) => {
    return httpClient
        .put(`/api/monthly-allowances/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const destroy = id => {
    return httpClient
        .delete(`/api/monthly-allowances/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const formViewModel = (options = {}) => {
    return httpClient
        .get('/api/monthly-allowances/form-view-models', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const exportExcel = attributes => {
    return httpClient
        .post('/api/monthly-allowances/export', attributes, {
            responseType: 'arraybuffer'
        })
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const importExcel = data => {
    return httpClient
        .post('/api/monthly-allowances/import-excels', data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export default {
    employeeProfile,
    payrollItemAllowance,
    store,
    get,
    update,
    destroy,
    formViewModel,
    exportExcel,
    importExcel
}
