import httpClient from '@/http-client'

export const get = (options = {}) => {
    return httpClient
        .get('/api/working-hours', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const formViewModel = (options = {}) => {
    return httpClient
        .get('/api/working-hours/form-view-models', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const store = data => {
    return httpClient
        .post('/api/working-hours', data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const update = (id, data) => {
    return httpClient
        .put(`/api/working-hours/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const destroy = id => {
    return httpClient
        .delete(`/api/working-hours/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const exportExcel = attributes => {
    return httpClient
        .post('/api/working-hours/export', attributes, {
            responseType: 'arraybuffer'
        })
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const importExcel = data => {
    return httpClient
        .post('/api/working-hours/import-excels', data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export default {
    formViewModel,
    store,
    get,
    update,
    destroy,
    exportExcel,
    importExcel
}
