import loanRecovery from "@/api/credit-operation/loan-recovery";

import { baseState, baseMutations } from "@/store/mixins";

export const state = {
    ...baseState,
    loanDisbursements: []
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_LAON_DISBURSEMENTS(state, data) {
        state.loanDisbursements = data;
    },
    CLEAR_RESOURCES(state) {
        state.loanDisbursements = [];
    }
};

export const actions = {
    fetch(context, attrubutes = {}) {
        let params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                is_web: true,
                filter: {
                    search: context.state.search
                }
            },
            attrubutes
        );
        return loanRecovery.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },
    store(context, data) {
        return loanRecovery.store(data);
    },
    update(context, { id, data }) {
        return loanRecovery.update(id, data);
    },
    destroy(context, { id, data }) {
        return loanRecovery.destroy(id, data);
    },
    async getLoanDisbursement(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: attributes.search
                }
            },
            attributes
        );
        let response = await loanRecovery.loanDisbursement({ params });
        context.commit("SET_LAON_DISBURSEMENTS", response.data);
    },
};
