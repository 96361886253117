import httpClient from '@/http-client'

export const employeeProfile = (Option = {}) => {
    return httpClient
        .get('/api/fixed-incentive-bonus/employee-profiles', Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const payItemAcentiveBonus = (Option = {}) => {
    return httpClient
        .get('/api/fixed-incentive-bonus/pay-item-acentive-bonuses', Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const store = data => {
    return httpClient
        .post('/api/fixed-incentive-bonus', data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const get = (options = {}) => {
    return httpClient
        .get('/api/fixed-incentive-bonus', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const update = (id, data) => {
    return httpClient
        .put(`/api/fixed-incentive-bonus/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const destroy = id => {
    return httpClient
        .delete(`/api/fixed-incentive-bonus/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export default {
    employeeProfile,
    payItemAcentiveBonus,
    store,
    get,
    destroy,
    update
}
