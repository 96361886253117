export default [
    {
        path: "/admin/shift-managements",
        name: "shift-management",
        meta: { requiresAuth: true },
        component: () =>
            import("@/pages/human-resource/shift-management/index.vue")
    },
    {
        path: "/admin/shift-managements/create",
        name: "shift-management-create",
        meta: { requiresAuth: true },
        component: () =>
            import("@/pages/human-resource/shift-management/create.vue")
    },
    {
        path: "/admin/shift-managements/:id/edit",
        name: "shift-management-edit",
        meta: { requiresAuth: true },
        component: () =>
            import("@/pages/human-resource/shift-management/edit.vue")
    }
];
