import httpClient from '@/http-client'

export const get = (options = {}) => {
    return httpClient
        .get('/api/teaching-calendars', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}

export default { get }
