import dailyAttendance from "@/api/human-resource/daily-attendance";
import { baseState, baseMutations } from "@/store/mixins";

export const state = {
    ...baseState,
    orgChartStructures: []
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_ORG_CHART_STRUCTURE(state, data) {
        state.orgChartStructures = data;
    }
};

export const actions = {
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return dailyAttendance.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },

    async getOrgChartStructure(context, attributes = {}) {
        let response = await dailyAttendance.orgChartStructure({
            params: attributes
        });
        context.commit("SET_ORG_CHART_STRUCTURE", response.data);
    }
};
