import httpClient from '@/http-client'

export const employeeProfile = data => {
    return httpClient
        .get('/api/reports/employee-profiles/employee-profiles', data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}

export const exportExcel = params => {
    return httpClient
        .post(
            '/api/reports/employee-profiles/excel', params,
            {
                responseType: 'arraybuffer'
            }
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}

export const formViewModel = (options = {}) => {
    return httpClient
        .get("/api/reports/employee-profiles/form-view-models", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
}

export default { employeeProfile, exportExcel, formViewModel }