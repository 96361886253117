<template>
    <div :style="{ width: width }">
        <Select
            v-model="value"
            :multiple="multiple"
            :max-tag-count="1"
            :placeholder="isPlaceholder ? placeholder : 'All'"
            @on-change="changeValue"
            :filterable="true"
            :filter-by-label="true"
            @on-set-default-options="setDefaultOptions"
        >
            <Option
                v-for="item in options"
                :value="item.branch_id"
                :key="item.branch_id"
                :label="item.branch_name_en"
            >
                {{ item.branch_name_en }}
            </Option>
        </Select>
    </div>
</template>

<script>
export default {
    name: 'branchFilter',
    props: {
        isPlaceholder: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: true
        },
        width: {
            type: String,
            default: '100%'
        }
    },
    data () {
        return {
            value: [],
            options: [],
            loading: false
        }
    },
    created () {
        this.fetchBranch()
    },
    computed: {
        placeholder () {
            return this.$t('all')
        }
    },
    methods: {
        fetchBranch () {
            this.loading = true
            this.$store
                .dispatch('auth/user/getBranchAssigned')
                .then(response => {
                    this.options = response.data
                    this.value.push(
                        this.$store.state.authUser.user.default_branch.branch_id
                    )
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        changeValue () {
            this.$emit('filter', this.value)
        },
        setDefaultOptions (options) {
            this.options = options
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'EMPLOYEE',
                desc: not.text
            })
        }
    }
}
</script>
<style lang="css" scoped>
.vue-treeselect__control {
    height: 34px !important;
}
</style>
