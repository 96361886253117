import httpClient from '@/http-client'

export const get = (options = {}) => {
  return httpClient
    .get('/api/pay-overtime-rule-by-shifts', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const store = data => {
  return httpClient
    .post('/api/pay-overtime-rule-by-shifts', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const find = (id, options = {}) => {
  return httpClient
    .get(`/api/pay-overtime-rule-by-shifts/${id}`, options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const update = (id, data) => {
  return httpClient
    .put(`/api/pay-overtime-rule-by-shifts/${id}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const destroy = id => {
  return httpClient
    .delete(`/api/pay-overtime-rule-by-shifts/${id}`)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const getShiftWorkingHours = (options = {}) => {
  return httpClient
    .get('/api/pay-overtime-rule-by-shifts/shift-working-hours', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const getOvertimeItems = (options = {}) => {
  return httpClient
    .get('/api/pay-overtime-rule-by-shifts/pay-item-overtimes', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export default { get, store, find, update, destroy, getShiftWorkingHours, getOvertimeItems }
