import group from '@/api/product/product-group'

import { baseState, baseMutations } from '@/store/mixins'
import { isEmpty } from "lodash";

export const state = {
  ...baseState,
  categories: []
}

export const getters = {}

export const mutations = {
  ...baseMutations,
  SET_CATEGORY(state, data) {
    state.categories = data
  }
}

export const actions = {
  fetch(context, attrubutes = {}) {
    let params = Object.assign(
      {
        page: context.state.pagination.page,
        perPage: context.state.pagination.perPage,
        filter: {
          search: context.state.search
        }
      },
      attrubutes
    )
    return group.get({ params }).then(response => {
      context.commit('RECEIVE_RESOURCES', response)
      return Promise.resolve(response)
    })
  },
  store(context, data) {
    return group.store(data)
  },
  addPrinter(context, data) {
    return group.addPrinter(data)
  },
  update(context, { id, data }) {
    return group.update(id, data)
  },
  async find(context, id) {
    if (isEmpty(context.state.edit_data)) {
      let response = await group.find(id)
      context.commit('SET_EDIT_DATA', response.data)
    }
  },
  destroy(context, id) {
    return group.destroy(id)
  },
  async getCategory(context) {
    if (!context.state.categories.length > 0) {
      let response = await group.category()
      context.commit('SET_CATEGORY', response.data)
    }
  }
}