import httpClient from '@/http-client'

export const store = data => {
    return httpClient
        .post('/api/pension-contribution-wages', data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const get = (options = {}) => {
    return httpClient
        .get('/api/pension-contribution-wages', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const update = (id, data) => {
    return httpClient
        .put(`/api/pension-contribution-wages/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const destroy = id => {
    return httpClient
        .delete(`/api/pension-contribution-wages/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export default {
    store,
    get,
    update,
    destroy
}
