export default [
    {
        path: "/admin/leave-records",
        name: "leave-record",
        meta: { requiresAuth: true },
        component: () => import("@/pages/human-resource/leave-record/index.vue")
    },
    {
        path: "/admin/leave-records/create",
        name: "leave-record-create",
        meta: { requiresAuth: true },
        component: () =>
            import("@/pages/human-resource/leave-record/create.vue")
    },
    {
        path: "/admin/leave-records/:id/edit",
        name: "leave-record-edit",
        meta: { requiresAuth: true },
        component: () => import("@/pages/human-resource/leave-record/edit.vue")
    }
];
