
export default [
  {
    path: '/admin/overtime-rule-by-shifts',
    name: 'overtime-rule-by-shift',
    meta: { requiresAuth: true },
    component: () => import('@/pages/payroll/overtime-rule-by-shift/index.vue')
  }
]

