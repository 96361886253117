import httpClient from "@/http-client";

export const get = (options = {}) => {
    return httpClient
        .get("/api/loan-disbursements", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/loan-disbursements", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const find = (id, options = {}) => {
    return httpClient
        .get(`/api/loan-disbursements/${id}`, options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return httpClient
        .put(`/api/loan-disbursements/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const destroy = (id, data) => {
    return httpClient
        .put(`/api/loan-disbursements/${id}/delete`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const employee = (Options = {}) => {
    return httpClient
        .get("/api/loan-disbursements/employees", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const customer = (Options = {}) => {
    return httpClient
        .get("/api/loan-disbursements/customers", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const loanType = (Option = {}) => {
    return httpClient
        .get("/api/loan-disbursements/loan-types", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const currency = (Option = {}) => {
    return httpClient
        .get("/api/loan-disbursements/currencies", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const repaymentMethod = (Option = {}) => {
    return httpClient
        .get("/api/loan-disbursements/repayment-methods", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const previewPdf = data => {
    return httpClient
        .get("/api/loan-disbursements/preview-pdf", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const exportExcel = attributes => {
    return httpClient
        .post("/api/loan-disbursements/export-excel", attributes, {
            responseType: "arraybuffer"
        })
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data));
};

export default {
    get,
    store,
    find,
    update,
    destroy,
    employee,
    customer,
    loanType,
    currency,
    repaymentMethod,
    previewPdf,
    exportExcel
};
