import httpClient from "@/http-client";

export const get = (Options = {}) => {
    return httpClient
        .get("/api/leave-rules", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/leave-rules", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const employeeType = (Option = {}) => {
    return httpClient
        .get("/api/leave-rules/employee-types", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const leaveType = (Option = {}) => {
    return httpClient
        .get("/api/leave-rules/leave-types", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export default { get, store, employeeType, leaveType };
