import profile from '@/api/product/profile'
import { mapKeys, camelCase, split, isEmpty } from 'lodash'

import { baseState, baseMutations } from '@/store/mixins'

class SerialPagination {
  constructor(paging = {}) {
    this.currentPage = paging.currentPage ? parseInt(paging.currentPage) : 1
    this.lastPage = paging.lastPage ? parseInt(paging.lastPage) : 0
    this.perPage = paging.perPage ? parseInt(paging.perPage) : 15
    this.total = paging.total ? parseInt(paging.total) : 0
    this.from = this.perPage * (this.currentPage - 1) + 1
    this.to = this.perPage * this.currentPage
  }
}

export const state = {
  ...baseState,
  branches: [],
  productTypes: [],
  productCategories: [],
  uomCategories: [],
  productMode: [],
  vendors: [],
  productRecipe: [],
  groups: [],
  serialStatus: [],
  serialResources: [],
  warehouses: [],
  setting: {},
  serialPagination: new SerialPagination(),

  formViewDatas: [],
}

export const getters = {}

export const mutations = {
  ...baseMutations,
  SET_VIEW_MODELS(state, { data, index }) {
    if (!index) {
      state.formViewDatas = data;
    } else {
      split(index, ",").forEach(el => {
        state.formViewDatas[el] = data[el];
      });
    }
  },

  RECEIVE_RESOURCES_SERIAL(state, { data, meta }) {
    state.serialResources = []
    data.forEach(resource => {
      state.serialResources.push(Object.assign(resource, { _deleting: false }))
    })

    state.serialPagination = new SerialPagination(
      mapKeys(meta, (v, k) => camelCase(k))
    )
  },
  SET_RECIPE_LIST(state, data) {
    state.productRecipe = data
  },
  RESET_RESOURCES_SERIAL(state) {
    state.serialResources = []
  },
  SET_SERIAL_STATUS(state, data) {
    state.serialStatus = data
  },
  RECEIVE_WAREHOUSES(state, data) {
    state.warehouses = data
  },
  SET_RESET_SERIAL_PAGE(state) {
    state.serialPagination = new SerialPagination()
  },
  CLEAR_SETUP(state) {
    state.branches = []
    state.productTypes = []
    state.productCategories = []
    state.uomCategories = []
    state.productMode = []
    state.vendors = []
    state.productRecipe = []
    state.groups = []
    state.serialStatus = []
  },
  SET_SETTING(state, data) {
    state.setting = data
  }
}

export const actions = {
  fetch(context, attrubutes = {}) {
    let params = Object.assign(
      {
        page: context.state.pagination.page,
        perPage: context.state.pagination.perPage,
        filter: {
          search: context.state.search
        }
      },
      attrubutes
    )
    return profile.get({ params }).then(response => {
      context.commit('RECEIVE_RESOURCES', response)
      return Promise.resolve(response)
    })
  },
  store(context, data) {
    return profile.store(data)
  },
  update(context, { id, data }) {
    return profile.update(id, data)
  },
  async find(context, id) {
    if (isEmpty(context.state.edit_data)) {
      let response = await profile.find(id)
      context.commit('SET_EDIT_DATA', response.data)
    }
  },
  fetchSerial(context, { id, attrubutes = {} }) {
    let params = Object.assign(
      {
        page: context.state.serialPagination.page,
        perPage: context.state.serialPagination.perPage
      },
      attrubutes
    )
    return profile.getSerial(id, { params }).then(response => {
      context.commit('RECEIVE_RESOURCES_SERIAL', response)
      return Promise.resolve(response)
    })
  },
  fetchAllSerial(context, attrubutes = {}) {
    let params = Object.assign(
      {},
      {
        page: context.state.serialPagination.page,
        perPage: context.state.serialPagination.perPage
      },
      attrubutes
    )
    return profile.getAllSerial({ params }).then(response => {
      context.commit('RECEIVE_RESOURCES_SERIAL', response)
      return Promise.resolve(response)
    })
  },
  destroy(context, id) {
    return profile.destroy(id)
  },
  fetchSerialHistory(context, serialNo) {
    return profile.getSerialHistory(serialNo)
  },
  async fetchBefore(context, { params, index }) {
    // let branch = await profile.branch()
    // context.commit('SET_BRANCH', branch.data)
    // let mode = await profile.mode()
    // context.commit('SET_PRODUCT_MODE', mode.data)
    // let type = await profile.type()
    // context.commit('SET_PRDUCT_TYPE', type.data)
    // let category = await profile.category()
    // context.commit('SET_PRODUCT_CATEGORY', category.data)
    // let uom_category = await profile.uomCategory()
    // context.commit('SET_UOM_CATEGORY', uom_category.data)
    // let supplier = await profile.supplier()
    // context.commit('SET_VENDOR_LIST', supplier.data)
    // let group = await profile.group()
    // context.commit('SET_GROUP_LIST', group.data)

    let response = await profile.formViewModel({ params });

    context.commit("SET_VIEW_MODELS", {
      data: response.data,
      index: index
    });
    return Promise.resolve(response);
  },
  async fetchSerialStatus(context) {
    let serialStatus = await profile.serialStatus()
    context.commit('SET_SERIAL_STATUS', serialStatus.data)
  },
  async recipe(context) {
    let response = await profile.recipe()
    context.commit('SET_RECIPE_LIST', response.data)
    return response
  },
  salecode(context, data) {
    return profile.salecode(data)
  },
  setImei(context, data) {
    return profile.setImei(data)
  },
  barcode(context, categoryId) {
    return profile.barcode(categoryId)
  },
  upload(context, { id, data }) {
    return profile.upload(id, data)
  },
  saleAvailable(context, id) {
    return profile.saleAvailable(id)
  },
  calculateStock(context, id) {
    return profile.calculateStock(id)
  },
  // employee(context) {
  //   return profile.employee()
  // },
  allowChangUomCat(context, productId) {
    return profile.allowChangUomCat(productId)
  },
  serialToExcel(context, productId) {
    return profile.serialToExcel({ product_id: productId })
  },
  fetchWarehouseAssigned(context, attrubutes = {}) {
    return profile.warehouseAssigned({ params: attrubutes }).then(response => {
      context.commit('RECEIVE_WAREHOUSES', response.data)
      return Promise.resolve(response)
    })
  },
  async getFormViewModel(context, { params, index }) {
    let response = await profile.formViewModel({ params });

    context.commit("SET_VIEW_MODELS", {
      data: response.data,
      index: index
    });
    return Promise.resolve(response);
  },
  exportExcel(context, data) {
    return profile.exportExcel(data)
  },
}
