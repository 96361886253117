import { baseState, baseMutations } from '@/store/mixins'
import nssfReport from '@/api/report/nssf-report'

export const state = {
    ...baseState,
    orgChartStructures: []
}
export const getters = {}

export const mutations = {
    ...baseMutations,
    SET_ORG_CHART_STRUCTURE (state, orgChartStructures) {
        state.orgChartStructures = orgChartStructures
    },
    SET_EMPLOYEE (state, data) {
        state.employees = data
    }
}
export const actions = {
    fetch (context, data) {
        const dataSend = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage
            },
            data
        )
        return nssfReport.view(dataSend).then(response => {
            context.commit('RECEIVE_RESOURCES', response)
            return Promise.resolve(response)
        })
    },
    async getOrgChartStructure (context, attributes = {}) {
        let response = await nssfReport.orgChartStructure({
            params: attributes
        })
        context.commit('SET_ORG_CHART_STRUCTURE', response.data)
    },
    async formViewModel (context, payload) {
        return await nssfReport.formViewModel(payload)
    }
}
