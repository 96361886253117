export default [
    {
        path: "/admin/branches",
        name: "branch",
        meta: true,
        component: () => import("@/pages/corporate/branch/index.vue")
    },
    {
        path: "/admin/branches/create",
        name: "branch-create",
        meta: true,
        component: () => import("@/pages/corporate/branch/create.vue"),
        props: route => ({ companies: route.params.companies })
    },
    {
        path: "/admin/branches/:id",
        name: "branch-edit",
        meta: true,
        component: () => import("@/pages/corporate/branch/edit.vue"),
        props: route => ({
            editData: route.params.editData,
            companies: route.params.companies
        })
    }
];
