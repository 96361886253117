import payrollCalculation from "@/api/payroll/payroll-calculation";
import { baseState, baseMutations } from "@/store/mixins";
import moment from "moment";
import { split } from 'lodash'

export const state = {
    ...baseState,
    cycle_year: moment().format("YYYY"),
    cycle_month: moment().format("MM"),
    formViewDatas: [],
    cycleOrders: [
        {
            value: 1,
            text: "First Cycle"
        },
        {
            value: 2,
            text: "Second Cycle"
        }
    ]
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_VIEW_MODELS(state, { data, index }) {
        if (!index) {
            state.formViewDatas = data;
        } else {
            split(index, ",").forEach(el => {
                state.formViewDatas[el] = data[el];
            });
        }
    },
    SET_CYCLE_YEAR(state, cycle_year) {
        state.cycle_year = cycle_year;
    },
    SET_CYCLE_MONTH(state, cycle_month) {
        state.cycle_month = cycle_month;
    }
};

export const actions = {
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                search: context.state.search,
                cycle_month: context.state.cycle_month,
                cycle_year: context.state.cycle_year,
                filter: {
                    cycle_year: context.state.cycle_year,
                    cycle_month: context.state.cycle_month
                }
            },
            attributes
        );
        return payrollCalculation.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },

    store(context, data) {
        return payrollCalculation.store(data);
    },
    getFormViewData(context, payload) {
        return payrollCalculation.formViewModel(payload);
    }
};
