import { baseState, baseMutations } from '@/store/mixins'
import pensionContributionWage from '@/api/payroll/pension-contribution-wage'
export const state = {
    ...baseState
}
export const getters = {}
export const mutations = {
    ...baseMutations
}
export const actions = {
    fetch (context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        )
        return pensionContributionWage.get({ params }).then(response => {
            context.commit('RECEIVE_RESOURCES', response)
            return Promise.resolve(response)
        })
    },
    store (context, data) {
        return pensionContributionWage.store(data)
    },
    update (context, { id, data }) {
        return pensionContributionWage.update(id, data)
    },
    destroy (context, id) {
        return pensionContributionWage.destroy(id)
    }
}
