export default [
    {
      path: '/admin/customers',
      name: 'customer',
      meta: { requiresAuth: true },
      component: () => import('@/pages/credit-operation/customer/index.vue')
    },
    {
      path: '/admin/customers/create',
      name: 'customer-create',
      meta: { requiresAuth: true },
      component: () => import('@/pages/credit-operation/customer/create.vue')
    },
    {
      path: '/admin/customers/:id/edit',
      name: 'customer-edit',
      meta: { requiresAuth: true },
      component: () => import('@/pages/credit-operation/customer/edit.vue')
    }
  ]
