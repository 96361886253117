import httpClient from '@/http-client'

export const dailyCollectionSchedule = (options = {}) => {
    return httpClient
        .get(
            '/api/reports/credit-operations/daily-collection-schedules',
            options
        )
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const disbursementList = (options = {}) => {
    return httpClient
        .get('/api/reports/credit-operations/disbursement-lists', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const summaryOfPortfolioAtRisk = (options = {}) => {
    return httpClient
        .get(
            '/api/reports/credit-operations/summary-of-portfolio-at-risks',
            options
        )
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const portfolioRepayment = (options = {}) => {
    return httpClient
        .get('/api/reports/credit-operations/portfolio-repayments', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const loanOverdue = (options = {}) => {
    return httpClient
        .get('/api/reports/credit-operations/loan-overdues', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const loanTransactionSummary = (options = {}) => {
    return httpClient
        .get(
            '/api/reports/credit-operations/loan-transaction-summaries',
            options
        )
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const loanTransactionDetail = (options = {}) => {
    return httpClient
        .get('/api/reports/credit-operations/loan-transaction-details', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const customerStatement = (options = {}) => {
    return httpClient
        .get('/api/reports/credit-operations/customer-statements', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const loanWriteOff = (options = {}) => {
    return httpClient
        .get('/api/reports/credit-operations/loan-write-offs', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const loanRecovery = (options = {}) => {
    return httpClient
        .get('/api/reports/credit-operations/loan-recoveries', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}

export const loanTransactionSummaryExportExcel = attributes => {
    return httpClient
        .post(
            '/api/reports/credit-operations/loan-transaction-summaries/excel',
            attributes,
            {
                responseType: 'arraybuffer'
            }
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const loanTransactionSummaryRawDataCSV = attributes => {
    return httpClient
        .post(
            '/api/reports/credit-operations/loan-transaction-summaries/excel',
            attributes
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const loanTransactionDetailExportExcel = attributes => {
    return httpClient
        .post(
            '/api/reports/credit-operations/loan-transaction-details/excel',
            attributes,
            {
                responseType: 'arraybuffer'
            }
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const disbursementListExportExcel = attributes => {
    return httpClient
        .post(
            '/api/reports/credit-operations/disbursement-lists/excel',
            attributes,
            {
                responseType: 'arraybuffer'
            }
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const disbursementListRawDataCSV = attributes => {
    return httpClient
        .post(
            '/api/reports/credit-operations/disbursement-lists/excel',
            attributes,
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const loanWriteOffExportExcel = attributes => {
    return httpClient
        .post(
            '/api/reports/credit-operations/loan-write-offs/excel',
            attributes,
            {
                responseType: 'arraybuffer'
            }
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const loanRecoveryExportExcel = attributes => {
    return httpClient
        .post(
            '/api/reports/credit-operations/loan-recoveries/excel',
            attributes,
            {
                responseType: 'arraybuffer'
            }
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const loanRecoveryRawDataCSV = attributes => {
    return httpClient
        .post(
            '/api/reports/credit-operations/loan-recoveries/excel',
            attributes,
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const loanDropOut = (options = {}) => {
    if (options.params.is_as_of_date) {
        return httpClient
            .get('/api/reports/credit-operations/loan-drop-outs-v2', options)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    }
    return httpClient
        .get('/api/reports/credit-operations/loan-drop-outs', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const loanDropOutExportExcel = attributes => {
    if (attributes.is_as_of_date) {
        return httpClient
            .post(
                '/api/reports/credit-operations/loan-drop-outs-v2/excel',
                attributes,
                {
                    responseType: 'arraybuffer'
                }
            )
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error.response.data))
    }
    return httpClient
        .post(
            '/api/reports/credit-operations/loan-drop-outs/excel',
            attributes,
            {
                responseType: 'arraybuffer'
            }
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const loanDropOutRawDataCSV = attributes => {
    return httpClient
        .post(
            '/api/reports/credit-operations/loan-drop-outs/excel',
            attributes,
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const customerStatementExportExcel = attributes => {
    return httpClient
        .post(
            '/api/reports/credit-operations/customer-statements/excel',
            attributes,
            {
                responseType: 'arraybuffer'
            }
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const customerStatementRawDataCSV = attributes => {
    return httpClient
        .post(
            '/api/reports/credit-operations/customer-statements/excel',
            attributes
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const dailyCollectionScheduleExportExcel = attributes => {
    return httpClient
        .post(
            '/api/reports/credit-operations/daily-collection-schedules/excel',
            attributes,
            {
                responseType: 'arraybuffer'
            }
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const dailyCollectionScheduleRawDataCSV = attributes => {
    return httpClient
        .post(
            '/api/reports/credit-operations/daily-collection-schedules/excel',
            attributes,
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const summaryOfPortfolioAtRiskExportExcel = attributes => {
    return httpClient
        .post(
            '/api/reports/credit-operations/summary-of-portfolio-at-risks/excel',
            attributes,
            {
                responseType: 'arraybuffer'
            }
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const summaryOfPortfolioAtRiskRawDataCSV = attributes => {
    return httpClient
        .post(
            '/api/reports/credit-operations/summary-of-portfolio-at-risks/excel',
            attributes
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const portfolioRepaymentExportExcel = attributes => {
    return httpClient
        .post(
            '/api/reports/credit-operations/portfolio-repayments/excel',
            attributes,
            {
                responseType: 'arraybuffer'
            }
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const portfolioRepaymentRawDataCSV = attributes => {
    return httpClient
        .post(
            '/api/reports/credit-operations/portfolio-repayments/excel',
            attributes
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const loanOverdueExportExcel = attributes => {
    return httpClient
        .post(
            '/api/reports/credit-operations/loan-overdues/excel',
            attributes,
            {
                responseType: 'arraybuffer'
            }
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const loanOverdueRawDataCSV = attributes => {
    return httpClient
        .post(
            '/api/reports/credit-operations/loan-overdues/excel',
            attributes
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const loanActiveByCo = (options = {}) => {
    return httpClient
        .get('/api/reports/credit-operations/loan-active-by-co', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const loanPaidOffByCo = (options = {}) => {
    return httpClient
        .get('/api/reports/credit-operations/loan-paid-off-by-co', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const loanPaidOffByCoExportExcel = attributes => {
    return httpClient
        .post(
            '/api/reports/credit-operations/loan-paid-off-by-co/excel',
            attributes,
            {
                responseType: 'arraybuffer'
            }
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const loanActiveByCoExportExcel = attributes => {
    return httpClient
        .post(
            '/api/reports/credit-operations/loan-active-by-co/excel',
            attributes,
            {
                responseType: 'arraybuffer'
            }
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const loanActiveByCoRawDataCSV = attributes => {
    return httpClient
        .post(
            '/api/reports/credit-operations/loan-active-by-co/excel',
            attributes
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const incentivePerformanceByCo = (options = {}) => {
    return httpClient
        .get(
            '/api/reports/credit-operations/incentive-performance-by-co',
            options
        )
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const incentivePerformanceByCoExportExcel = attributes => {
    return httpClient
        .post(
            '/api/reports/credit-operations/incentive-performance-by-co/excel',
            attributes,
            {
                responseType: 'arraybuffer'
            }
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const devicePostingLogExportExcel = attributes => {
    return httpClient
        .post(
            '/api/reports/credit-operations/device-posting-logs/excel',
            attributes,
            {
                responseType: 'arraybuffer'
            }
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export default {
    dailyCollectionSchedule,
    disbursementList,
    summaryOfPortfolioAtRisk,
    portfolioRepayment,
    loanOverdue,
    loanTransactionSummary,
    loanTransactionDetail,
    customerStatement,
    loanWriteOff,
    loanRecovery,
    loanTransactionSummaryExportExcel,
    loanTransactionDetailExportExcel,
    disbursementListExportExcel,
    loanWriteOffExportExcel,
    loanRecoveryExportExcel,
    loanDropOut,
    loanDropOutExportExcel,
    customerStatementExportExcel,
    dailyCollectionScheduleExportExcel,
    summaryOfPortfolioAtRiskExportExcel,
    portfolioRepaymentExportExcel,
    loanOverdueExportExcel,
    loanActiveByCo,
    loanActiveByCoExportExcel,
    incentivePerformanceByCo,
    incentivePerformanceByCoExportExcel,
    devicePostingLogExportExcel,
    loanPaidOffByCo,
    disbursementListRawDataCSV,
    loanDropOutRawDataCSV,
    loanRecoveryRawDataCSV,
    dailyCollectionScheduleRawDataCSV,
    loanActiveByCoRawDataCSV,
    customerStatementRawDataCSV,
    summaryOfPortfolioAtRiskRawDataCSV,
    portfolioRepaymentRawDataCSV,
    loanTransactionSummaryRawDataCSV,
    loanOverdueRawDataCSV,
    loanPaidOffByCoExportExcel
}
