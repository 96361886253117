<template>
  <div class="d-flex align-items-center tw-space-x-3">
    <span >{{ $t('show') }}</span>
    <select
      class="form-control input-sm"
      @change="onChangeSelected"
      :value="value"
    >
      <option>15</option>
      <option>30</option>
      <option>50</option>
      <option>100</option>
    </select>
    <span > {{ $t('entries') }}</span>
  </div>
</template>

<script>
export default {
  name: 'PerPage',
  props: ['value'],
  methods: {
    onChangeSelected($event) {
      this.$emit('change', parseInt($event.target.value))
    }
  }
}
</script>
