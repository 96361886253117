import httpClient from '@/http-client'
export const store = data => {
    return httpClient
        .post('/api/item-compensates', data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const get = (options = {}) => {
    return httpClient
        .get('/api/item-compensates', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const itemCustomRule = (Option = {}) => {
    return httpClient
        .get('/api/item-compensates/item-custom-rules', Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const update = (id, data) => {
    return httpClient
        .put(`/api/item-compensates/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const destroy = id => {
    return httpClient
        .delete(`/api/item-compensates/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export default { store, get, itemCustomRule, update, destroy }
