import basicSalaryHistory from "@/api/report/basic-salary-history";
import { baseState, baseMutations } from "@/store/mixins";

export const state = {
    ...baseState,
    orgChartStructures: []
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_ORG_CHART_STRUCTURE(state, data) {
        state.orgChartStructures = data;
    },
    CLEAR_EMPLOYEE(state) {
        state.employees = [];
    },
    SET_EMPLOYEE(state, data) {
        state.employees = data;
    }
};

export const actions = {
    fetch(context, data) {
        const dataSend = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage
            },
            data
        );
        return basicSalaryHistory.view(dataSend).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },

    async getOrgChartStructure(context, attributes = {}) {
        let response = await basicSalaryHistory.orgChartStructure({
            params: attributes
        });
        context.commit("SET_ORG_CHART_STRUCTURE", response.data);
    },
    async formViewModel(context, payload) {
        return await basicSalaryHistory.formViewModel(payload);
    },
    exportRawData(context, { ...attributes }) {
        return basicSalaryHistory.exportRawData(
            Object.assign(
                {
                    template: localStorage.getItem('langCode')
                },
                attributes
            )
        )
    },
};
