<template>
    <li v-if="active">
        <i class="bg-success tw-text-white tw-text-left"
            >L{{ model.level_counter }}</i
        >
        <div
            class="item-container"
            :class="{
                bold: model.items > 0,
                'no-employee': !model.employee_name_en
            }"
            @dblclick="changeType(model)"
        >
            <b style="width: 100%">
                <strong
                    v-if="
                        model.org_chart_structure
                            ? model.org_chart_structure.length < 15
                            : true
                    "
                >
                    {{ model.org_chart_structure }}
                </strong>
                <marquee v-else width="75%" direction="left" scrollamount="3">
                    {{ model.org_chart_structure }}
                </marquee>
            </b>
            <img
                v-if="images"
                class="user-avatar img-org-str"
                v-lazy="{
                    src: photo(model),
                    error:
                        model.sex == 'Female'
                            ? require('@/assets/staff-default-women.png')
                            : require('@/assets/staff-default-man.png'),
                    loading: require('@/assets/Rolling-1s-200px.svg'),
                    preLoad: 1.3,
                    attempt: 1
                }"
            />
            <p style="float:right">
                <span v-if="edit" @click="editItem">✎</span>
                <span v-if="remove" @click="removeItem">✖</span>
            </p>
            <span class="item">
                <span v-if="!editMode" @click="toggle(model)">
                    <strong class="tw-whitespace-nowrap text-blue">{{
                        model.employee_name_en
                    }}</strong>
                    <br />
                    <small class="tw-text-gray-400">{{
                        model.position_name_en
                    }}</small>
                    <br />
                    <span
                        v-if="loading"
                        class="fas fa-circle-notch fa-spin tw-text-blue-500"
                        aria-hidden="true"
                        style="font-size: 20px;"
                    >
                    </span>
                    <span v-else>
                        <span v-if="model.items > 0" style="font-size: 15px;">
                            [{{ open ? "-" : "+" }}]
                        </span>
                    </span>
                </span>
                <input v-else v-model="model.name" @blur="editMode = false" />
            </span>
        </div>
        <ul v-show="open" v-if="model.items > 0">
            <child-item
                v-for="(model, index) in model.children"
                :key="index"
                :model="model"
                :append="append"
                :remove="remove"
                :edit="edit"
                :images="images"
            >
            </child-item>
            <li v-if="append" class="add" @click="addChild">
                <div>
                    <span>+</span>
                </div>
            </li>
        </ul>
    </li>
</template>

<script>
import Vue from "vue";
Vue.directive("click-outside", {
    bind: function(el, binding, vnode) {
        el.event = function(event) {
            if (!(el == event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener("dblclick", el.event);
    },
    unbind: function(el) {
        document.body.removeEventListener("dblclick", el.event);
    }
});
export default {
    name: "child-item",
    props: {
        model: Object,
        append: {
            default: false,
            type: Boolean
        },
        remove: {
            default: false,
            type: Boolean
        },
        edit: {
            default: false,
            type: Boolean
        },
        images: {
            default: false,
            type: Boolean
        }
    },
    data: function() {
        return {
            open: false,
            active: true,
            editMode: false,
            loading: false
        };
    },
    methods: {
        photo(record) {
            if (record.photo) {
                return "/api/files?filePath=" + record.photo;
            }

            return record.sex == "Female"
                ? require("@/assets/staff-default-women.png")
                : require("@/assets/staff-default-man.png");
        },
        toggle: function(record) {
            if (record.items > 0) {
                this.open = !this.open;
                if (this.open && record.children.length == 0) {
                    this.fetchData(record);
                }
            }
        },
        changeType: function(record) {
            if (record.items == 0 && this.append) {
                this.$set(this.model, "children", []);
                this.addChild();
                this.open = true;
            }
        },
        addChild: function() {
            this.model.children.push({
                name: "New Element",
                photo: ""
            });
        },
        removeItem: function() {
            this.active = false;
        },
        editItem: function() {
            this.editMode = true;
        },
        fetchData(record) {
            this.loading = true;
            this.$store
                .dispatch(
                    "humanResource/positionHierarchy/fetchChildrenPosition",
                    {
                        position_id: record.position_id
                    }
                )
                .then(response => {
                    if (response.data.length > 0) {
                        record.children = response.data;
                    }
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>
<style scoped>
ul {
    padding-top: 20px;
    position: relative;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
}
li {
    float: left;
    text-align: center;
    list-style-type: none;
    position: relative;
    padding: 20px 5px 0 5px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
}
li::before,
li::after {
    content: "";
    position: absolute;
    top: 0;
    right: 50%;
    border-top: 1px solid #ccc;
    width: 50%;
    height: 20px;
}
li::after {
    right: auto;
    left: 50%;
    border-left: 1px solid #ccc;
}
li:only-child::after,
li:only-child::before {
    display: none;
}
li:only-child {
    padding-top: 0;
}
li:first-child::before,
li:last-child::after {
    border: 0 none;
}
li:last-child::before {
    border-right: 1px solid #ccc;
    border-radius: 0 5px 0 0;
    -webkit-border-radius: 0 5px 0 0;
    -moz-border-radius: 0 5px 0 0;
}
li:first-child::after {
    border-radius: 5px 0 0 0;
    -webkit-border-radius: 5px 0 0 0;
    -moz-border-radius: 5px 0 0 0;
}
ul ul::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    border-left: 1px solid #ccc;
    width: 0;
    height: 20px;
}
li div {
    border: 1px solid #ccc;
    text-decoration: none;
    font-family: arial, verdana, tahoma;
    font-size: 11px;
    display: inline-block;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
}
li b {
    padding: 6px 1px;
    text-decoration: none;
    font-family: arial, verdana, tahoma;
    font-size: 11px;
    display: inline-block;
    background-color: #dfdede;
}

li i {
    padding: 0 4px;
    font-family: arial, verdana, tahoma;
    font-size: 11px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    position: absolute;
}
li div.item-container {
    color: #777;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    width: 130px;
}
.no-employee {
    background-color: #f0eeee;
    opacity: 0.6;
    color: #ccc;
}
li div:hover,
li div:hover + ul li div {
    background: #dddddd;
    color: #000;
    border: 1px solid #777;
}
li div:hover + ul li::after,
li div:hover + ul li::before,
li div:hover + ul::before,
li div:hover + ul ul::before {
    border-color: #777;
}
.orgTree-footer table tr td:first-child {
    padding-right: 30px;
}
.orgTree-footer table tr td:nth-child(2) {
    font-weight: bold;
}
.orgTree-footer table tr td {
    padding-bottom: 10px;
}
.user-avatar {
    height: 24px;
    border-radius: 5px;
    margin-right: 10px;
    border: 1px solid #eaeaea;
    margin: auto;
}
.user-avatar.img-org-str {
    height: 80px !important;
}
.btn-primary:not(:hover) {
    color: #fff !important;
}
.item {
    cursor: pointer;
}
.bold {
    font-weight: bold;
}
</style>
