import loanCurrency from "@/api/credit-operation/loan-currency";

import { baseState, baseMutations } from "@/store/mixins";

export const state = {
    ...baseState,
    loanTypes: [],
    currencies: []
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_LOAN_TYPES(state, data) {
        state.loanTypes = data;
    },
    SET_CURRENCIES(state, data) {
        state.currencies = data;
    }
};

export const actions = {
    fetch(context, attrubutes = {}) {
        let params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attrubutes
        );
        return loanCurrency.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },
    store(context, data) {
        return loanCurrency.store(data);
    },
    update(context, { id, data }) {
        return loanCurrency.update(id, data);
    },
    async getLoanType(context, attributes = {}) {
        let response = await loanCurrency.loanType({ params: attributes });
        context.commit("SET_LOAN_TYPES", response.data);
    },
    async getCurrency(context, attributes = {}) {
        let response = await loanCurrency.currency({ params: attributes });
        context.commit("SET_CURRENCIES", response.data);
    }
};
