import itemCompensate from '@/api/payroll/item-compensate'
import { baseState, baseMutations } from '@/store/mixins'

export const state = {
    ...baseState,
    itemCustomRules: []
}
export const getters = {}

export const mutations = {
    ...baseMutations,
    SET_ITEM_CUSTOM_RULES (state, itemCustomRules) {
        state.itemCustomRules = itemCustomRules
    }
}
export const actions = {
    store (context, data) {
        return itemCompensate.store(data)
    },
    fetch (context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        )
        return itemCompensate.get({ params }).then(response => {
            context.commit('RECEIVE_RESOURCES', response)
            return Promise.resolve(response)
        })
    },
    async getItemCustomRule (context, attributes = {}) {
        let response = await itemCompensate.itemCustomRule({
            params: attributes
        })
        context.commit('SET_ITEM_CUSTOM_RULES', response.data)
    },
    update (context, { id, data }) {
        return itemCompensate.update(id, data)
    },
    destroy (context, id) {
        return itemCompensate.destroy(id)
    }
}
