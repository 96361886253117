import httpClient from "@/http-client";

export const get = (options = {}) => {
    return httpClient
        .get("/api/basic-salary-formulas", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const basicSalaryFormulaParameter = (options = {}) => {
    return httpClient
        .get("/api/basic-salary-formulas/basic-salary-formula-parameters", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const update = (id, data) => {
    return httpClient
        .put(`/api/basic-salary-formulas/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export default{get,update,basicSalaryFormulaParameter}
