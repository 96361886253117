import PayUserVariable from '@/api/payroll/pay-user-variable'
import { baseState, baseMutations } from '@/store/mixins'

export const state = {
  ...baseState,
  systemVariables: [],
  userVariables: []
}

export const getters = {}

export const mutations = {
  ...baseMutations,

  RECEIVE_SYSTEM_VARIABLES(state, systemVariables) {
    state.systemVariables = systemVariables
  },

  RECEIVE_USER_VARIABLES(state, userVariables) {
    state.userVariables = userVariables
  }
}

export const actions = {
  fetch(context, attributes = {}) {
    let params = Object.assign(
      {
        page: context.state.pagination.page,
        perPage: context.state.pagination.perPage,
        filter: {
          search: context.state.search
        }
      },
      attributes
    )
    return PayUserVariable.get({ params }).then(response => {
      context.commit("RECEIVE_RESOURCES", response);
      return Promise.resolve(response);
    });
  },

  store(context, data) {
    return PayUserVariable.store(data)
  },

  update(context, { id, data }) {
    return PayUserVariable.update(id, data)
  },

  destroy(context, id) {
    return PayUserVariable.destroy(id);
  },

  async getSystemVariables(context, attributes = {}) {
    let response = await PayUserVariable.getSystemVariables({
      params: attributes
    });
    context.commit("RECEIVE_SYSTEM_VARIABLES", response.data);
  },

  async getUserVariables(context, attributes = {}) {
    let response = await PayUserVariable.getUserVariables({
      params: attributes
    });
    context.commit("RECEIVE_USER_VARIABLES", response.data);
  },
}
