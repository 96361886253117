import organizationalStructure from "@/api/human-resource/organizational-structure";
import { baseState, baseMutations } from "@/store/mixins";

export const state = {
    ...baseState,
    companies: [],
    levelCounters: [],
    parentStructures: []
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_COMPANIES(state, data) {
        state.companies = data;
    },
    SET_LEVEL_COUNTER(state, data) {
        state.levelCounters = data;
    },
    SET_PARENT_STRUCTURE(state, data) {
        state.parentStructures = data;
    }
};

export const actions = {
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return organizationalStructure.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },

    store(context, data) {
        return organizationalStructure.store(data);
    },

    update(context, { id, data }) {
        return organizationalStructure.update(id, data);
    },

    destroy(context, id) {
        return organizationalStructure.destroy(id);
    },
    async getCompany(context, attributes = {}) {
        let response = await organizationalStructure.company({
            params: attributes
        });
        context.commit("SET_COMPANIES", response.data);
    },
    async getLevelCounter(context, attributes = {}) {
        let response = await organizationalStructure.levelCounter({
            params: attributes
        });
        context.commit("SET_LEVEL_COUNTER", response.data);
    },
    async getParentStructure(context, attributes = {}) {
        let response = await organizationalStructure.parentStructure({
            params: attributes
        });
        context.commit("SET_PARENT_STRUCTURE", response.data);
    }
};
