<template>
    <div>
        <!-- begin brand -->
        <div class="login-header">
            <div class="brand tw-text-center">
                <div class="tw-w-full tw-flex tw-justify-center">
                    <img
                        class="tw-rounded-full tw-w-40 tw-h-40 tw-flex tw-items-center tw-justify-center tw-object-cover"
                        :src="photo"
                        @error="onError"
                        alt=""
                    />
                </div>
                <b class="tw-uppercase">{{ name }}</b>
            </div>
            <!-- <div class="icon">
            <i class="fa fa-lock"></i>
          </div> -->
        </div>
        <!-- end brand -->
        <!-- begin login-content -->
        <div class="login-content">
            <form @submit.prevent="login" class="margin-bottom-0">
                <div class="form-group tw-mb-5">
                    <input
                        :type="input_type"
                        class="form-control form-control-lg"
                        placeholder="Password"
                        required
                        v-model.trim="model.password"
                        @keyup.enter="onSwitch"
                        ref="password"
                        :class="{
                            'is-invalid':
                                errors.has('password') ||
                                $v.model.password.$error
                        }"
                    />
                    <a href="#" @click.prevent="showPassword">
                        <i
                            v-if="input_type === 'text'"
                            class="toggle-password fas fa-eye"
                        ></i>
                        <i
                            v-else
                            class="toggle-password fa fa-fw fa-eye-slash"
                        ></i>
                    </a>
                    <div
                        class="alert alert-danger fade show m-b-20"
                        v-if="errors.has('password')"
                    >
                        {{ errors.first("password") }}
                    </div>
                    <span class="text-danger" v-if="passwordErrors.length > 0">{{
                        passwordErrors[0]
                    }}</span>
                </div>
                <div class="login-buttons">
                    <ts-button
                        type="submit"
                        @click.prevent="onSwitch"
                        class="btn btn-success d-block btn-lg tw-w-full"
                    >
                        Sign me in
                    </ts-button>
                </div>
            </form>
        </div>
        <!-- end login-content -->
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
import { required } from "vuelidate/lib/validators";

export default {
    name: "switchToHris",
    data() {
        return {
            errors: new Errors(),
            waiting: false,
            default_user: require("../../assets/default_user.png"),
            input_type: "password",
            model: {
                password: null
            }
        };
    },
    validations: {
        model: {
            password: { required }
        },
        form: ["model.password"]
    },
    mounted() {
        this.$refs.password.focus();
    },
    computed: {
        isStaff() {
            return this.$store.getters.isStaffLogin;
        },
        name() {
            return this.$store.state.authUser.user.user_name;
        },
        photo() {
            let p = this.$store.state.authUser
                ? this.$store.state.authUser.user_profile
                : null;
            if (p) {
                return p;
            }
            return this.default_user;
        },
        passwordErrors() {
            const errors = [];
            if (!this.$v.model.password.$error) return errors;
            !this.$v.model.password.required && errors.push("Required");
            return errors;
        }
    },
    methods: {
        onSwitch() {
            this.$v.form.$touch();
            var invalid = this.$v.form.$invalid;
            if (invalid) {
                return;
            }
            this.$nextTick(() => {
                const redirect = "home";
                const password = this.model.password;
                VueCookies.remove("token");
                VueCookies.remove("refresh_token");
                window.location.replace(
                    this.$store.state.authUser.company.hris_url +
                        `/login?redirectTo=${redirect}&auto=1&l=${this.$store.state.authUser.user.user_name}&p=${password}`
                );
            });
        },
        async logout() {
            await this.$store.dispatch("logout");
        },
        onError() {
            event ? (event.target.src = this.default_user) : "";
        },
        showPassword() {
            if (this.input_type === "password") {
                this.input_type = "text";
            } else {
                this.input_type = "password";
            }
        }
    }
};
</script>
<style scoped>
.toggle-password {
    float: right;
    cursor: pointer;
    margin-right: 10px;
    margin-top: -30px;
    font-size: 15px;
}
</style>
