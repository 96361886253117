export default [
  {
    path: '/admin/product-profiles',
    name: 'product-profile',
    meta: { requiresAuth: true },
    component: () => import('@/pages/product/profile/index.vue')
  },
  {
    path: '/admin/product-profiles/create',
    name: 'product-profile-create',
    meta: { requiresAuth: true },
    component: () => import('@/pages/product/profile/create.vue')
  },
  {
    path: '/admin/product-profiles/:id/edit',
    name: 'product-profile-edit',
    meta: { requiresAuth: true },
    component: () => import('@/pages/product/profile/edit.vue')
  },
  // not apply profile-serail yet
  // {
  //   path: '/admin/product-profiles/:id/serials',
  //   name: 'product-profile-serail',
  //   meta: { requiresAuth: true },
  //   component: () => import('@/pages/product/profile/serial/index.vue')
  // },
  // {
  //   path: '/admin/product-profiles/all-serials',
  //   name: 'product-profile-all-serail',
  //   meta: { requiresAuth: true },
  //   component: () => import('@/pages/product/profile/serial/all-serial.vue')
  // },
  // {
  //   path: '/admin/serial-profiles',
  //   name: 'product-profile-all-serail',
  //   meta: { requiresAuth: true },
  //   component: () => import('@/pages/product/profile/serial/all-serial.vue')
  // }
]