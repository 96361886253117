import loanRepayment from "@/api/credit-operation/loan-repayment";
import { baseState, baseMutations } from "@/store/mixins";

export const state = {
    ...baseState,
    employees: [],
    loanDisbursements: []
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_EMPLOYEES(state, data) {
        state.employees = data;
    },
    SET_LAON_DISBURSEMENTS(state, data) {
        state.loanDisbursements = data;
    },
    CLEAR_RESOURCES(state) {
        state.loanDisbursements = [];
        state.employees = [];
    }
};

export const actions = {
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: 1,
                search: context.state.search
            },
            attributes
        );
        return loanRepayment.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },

    store(context, data) {
        return loanRepayment.store(data);
    },

    destroy(context, { id, data }) {
        return loanRepayment.destroy(id, data);
    },
    async getEmployees(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    status_id: 1
                }
            },
            attributes
        );
        let response = await loanRepayment.employee({ params });
        context.commit("SET_EMPLOYEES", response.data);
    },

    confirmRepayment(context, payload = {}) {
        return loanRepayment.confirm(payload);
    },

    async getLoanDisbursement(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: attributes.search,
                    approve_status: 2, //Approved
                    is_finished: false,
                    is_write_off: false
                }
            },
            attributes
        );
        let response = await loanRepayment.loanDisbursement({ params });
        context.commit("SET_LAON_DISBURSEMENTS", []);
        context.commit("SET_LAON_DISBURSEMENTS", response.data);
    },
    async fetchRepayment(context, attributes = {}) {
        return loanRepayment.repayment({
            params: attributes
        });
    }
};
