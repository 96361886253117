import httpClient from "@/http-client";

export const get = (Options = {}) => {
    return httpClient
        .get("/api/approval-flows", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/approval-flows", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return httpClient
        .put(`/api/approval-flows/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const changeIsEnabled = (id, data) => {
    return httpClient
        .put(`/api/approval-flows/change-is-enabled/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const logicalPosition = (Option = {}) => {
    return httpClient
        .get("/api/approval-flows/logical-position", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const employee = (Option = {}) => {
    return httpClient
        .get("/api/approval-flows/employee", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const approvalType = (Option = {}) => {
    return httpClient
        .get("/api/approval-flows/approval-type", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const approverType = (Option = {}) => {
    return httpClient
        .get("/api/approval-flows/approver-type", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const approvalFlowParameter = id => {
    return httpClient
        .get(`/api/approval-flows/approval-flow-parameter/${id}`, Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export default {
    get,
    store,
    update,
    changeIsEnabled,
    logicalPosition,
    employee,
    approvalType,
    approverType,
    approvalFlowParameter
};
