import httpClient from "@/http-client";

export const get = (options = {}) => {
    return httpClient
        .get("/api/customer-loan-requests", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/customer-loan-requests", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const find = (id, options = {}) => {
    return httpClient
        .get(`/api/customer-loan-requests/${id}`, options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return httpClient
        .put(`/api/customer-loan-requests/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const approve = (id, data) => {
    return httpClient
        .put(`/api/customer-loan-requests/${id}/approve`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const destroy = (id, data) => {
    return httpClient
        .put(`/api/customer-loan-requests/${id}/delete`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const customer = (Options = {}) => {
    return httpClient
        .get("/api/customer-loan-requests/customers", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const employee = (Options = {}) => {
    return httpClient
        .get("/api/customer-loan-requests/employees", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const loanType = (Option = {}) => {
    return httpClient
        .get("/api/customer-loan-requests/loan-types", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const currency = (Option = {}) => {
    return httpClient
        .get("/api/customer-loan-requests/currencies", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const repaymentMethod = (Option = {}) => {
    return httpClient
        .get("/api/customer-loan-requests/repayment-methods", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const purpose = (Option = {}) => {
    return httpClient
        .get("/api/customer-loan-requests/purpose", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const previewPdf = id => {
    return httpClient
        .get(`/api/customer-loan-requests/${id}/preview-pdf`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}

export default {
    get,
    store,
    find,
    update,
    approve,
    destroy,
    customer,
    employee,
    loanType,
    currency,
    repaymentMethod,
    purpose,
    previewPdf
};
