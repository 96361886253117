import { baseState, baseMutations } from '@/store/mixins'
import workingHours from '@/api//payroll/working-hours'

export const state = {
    ...baseState
}
export const getters = {}

export const mutations = {
    ...baseMutations
}
export const actions = {
    formViewModel (context, payload) {
        return workingHours.formViewModel(payload)
    },
    store (context, data) {
        return workingHours.store(data)
    },
    fetch (context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        )
        return workingHours.get({ params }).then(response => {
            context.commit('RECEIVE_RESOURCES', response)
            return Promise.resolve(response)
        })
    },
    update (context, { id, data }) {
        return workingHours.update(id, data)
    },
    destroy (context, id) {
        return workingHours.destroy(id)
    },
    exportExcel (context, data) {
        return workingHours.exportExcel(data)
    },
    importExcel (context, data) {
        return workingHours.importExcel(data)
    }
}
