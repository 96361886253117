import httpClient from "@/http-client";

export const get = (Options = {}) => {
    return httpClient
        .get("/api/leave-type-sub-items", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/leave-type-sub-items", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return httpClient
        .put(`/api/leave-type-sub-items/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const destroy = id => {
    return httpClient
        .delete(`/api/leave-type-sub-items/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const leaveType = (Option = {}) => {
    return httpClient
        .get("/api/leave-type-sub-items/leave-types", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export default { get, store, update, destroy, leaveType };
