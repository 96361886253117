import httpClient from "@/http-client";

export const get = (options = {}) => {
    return httpClient
        .get("/api/leave-records", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/leave-records", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const find = (id, options = {}) => {
    return httpClient
        .get(`/api/leave-records/${id}`, options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return httpClient
        .put(`/api/leave-records/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const destroy = (id, data) => {
    return httpClient
        .put(`/api/leave-records/${id}/delete`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const getTime = data => {
    return httpClient
        .post("/api/leave-records/get-times", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const getTotalDay = data => {
    return httpClient
        .post("/api/leave-records/get-total-days", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const employee = (Option = {}) => {
    return httpClient
        .get("/api/leave-records/employees", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const leaveType = (Option = {}) => {
    return httpClient
        .get("/api/leave-records/leave-types", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const leaveTypeSubItem = (Option = {}) => {
    return httpClient
        .get("/api/leave-records/leave-type-sub-item", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export default {
    get,
    store,
    find,
    update,
    destroy,
    getTime,
    employee,
    leaveType,
    getTotalDay,
    leaveTypeSubItem
};
