import loanTransfer from "@/api/credit-operation/loan-transfer";

import { baseState, baseMutations } from "@/store/mixins";

export const state = {
    ...baseState,
    loanDisbursements: [],
    employees: []
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_LAON_DISBURSEMENTS(state, data) {
        state.loanDisbursements = data;
    },
    SET_EMPLOYEES(state, data) {
        state.employees = data;
    },
    CLEAR_RESOURCES(state) {
        state.employees = [];
        state.loanDisbursements = [];
    }
};

export const actions = {
    fetch(context, attrubutes = {}) {
        let params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attrubutes
        );
        return loanTransfer.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },
    store(context, data) {
        return loanTransfer.store(data);
    },
    update(context, { id, data }) {
        return loanTransfer.update(id, data);
    },
    destroy(context, { id, data }) {
        return loanTransfer.destroy(id, data);
    },
    async getEmployees(context, attributes = {}) {
        let response = await loanTransfer.employee({ attributes });
        context.commit("SET_EMPLOYEES", response.data);
    },
    async getLoanDisbursement(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: attributes.search,
                    approve_status: 2, //Approved
                    is_finished: false,
                    co_id: attributes.co_id,
                }
            },
            attributes
        );
        let response = await loanTransfer.loanDisbursement({ params });
        context.commit("SET_LAON_DISBURSEMENTS", response.data);
    }
};
