import serviceTicket from '@/api/customer-service/service-ticket'

import { baseState, baseMutations } from '@/store/mixins'
import { split, isEmpty, includes } from 'lodash'

export const state = {
  ...baseState,
  formViewDatas: [],
  branch_id: [],
  ticket_status: undefined
}

export const getters = {
  getTicketStatusList: state => ticket_status => {
    if (ticket_status && state.formViewDatas.serviceTicketStatus) {
      if (ticket_status == 1 || ticket_status == 2) {
        return state.formViewDatas.serviceTicketStatus
          .filter(el => includes([1, 2], el.ticket_status_id))
      } else if (ticket_status == 3 || ticket_status == 4 || ticket_status == 5) {
        return state.formViewDatas.serviceTicketStatus
          .filter(el => includes([3, 4, 5], el.ticket_status_id))
      }
      else if (ticket_status == 6 || ticket_status == 7) {
        return state.formViewDatas.serviceTicketStatus
          .filter(el => includes([6, 7], el.ticket_status_id))
      }
    } else {
      return state.formViewDatas.serviceTicketStatus
    }
  },
  getUom: state => productId => {
    if (productId) {
      return state.formViewDatas.product
        .filter(p => p.product_id == productId)
        .map(el => el.uom_category.unit_of_measures)
        .flat()
    }
    return state.formViewDatas.uom
  },
  isDefaultUom: state => productId => {
    if (productId) {
      return state.formViewDatas.product
        .filter(p => p.product_id == productId)
        .map(el => el.uom_category.unit_of_measures)
        .flat()
        .filter(d => d.is_default == true)
        .map(el => el.uom_id)
        .reduce((init, value) => (init = value))
    }
    return null
  },
}

export const mutations = {
  ...baseMutations,
  SET_BRANCH_ID(state, value) {
    state.branch_id = value
  },
  SET_TICKET_STATUS(state, value) {
    state.ticket_status = value
  },
  SET_VIEW_MODELS(state, { data, index }) {
    if (!index) {
      state.formViewDatas = data;
    } else {
      split(index, ",").forEach(el => {
        state.formViewDatas[el] = data[el];
      });
    }
  },
  PUSH_NEW_CUSTOMER(state, data) {
    // if not exists
    let exist = state.formViewDatas.customers.find(c => c.customer_id == data.customer_id)
    if (!exist) {
      state.formViewDatas.customers.push({
        contact_number: data.contact_number,
        customer_id: data.customer_id,
        customer_name: data.customer_name,
        front_photo: data.front_photo
      })
    }
  },
}

export const actions = {
  fetch(context, attrubutes = {}) {
    const params = Object.assign(
      {
        page: context.state.pagination.page,
        perPage: context.state.pagination.perPage,
        filter: {
          branch_id: context.state.branch_id,
          search: context.state.search,
          ticket_status: context.state.ticket_status,
        }
      },
      attrubutes
    )
    return serviceTicket.get({ params }).then(response => {
      context.commit('RECEIVE_RESOURCES', response)
      return Promise.resolve(response)
    })
  },
  store(context, data) {
    return serviceTicket.store(data)
  },
  update(context, { id, data }) {
    return serviceTicket.update(id, data)
  },
  updateTicketStatus(context, { id, data }) {
    return serviceTicket.updateTicketStatus(id, data)
  },
  async find(context, id) {
    if (isEmpty(context.state.edit_data)) {
      let response = await serviceTicket.find(id)
      context.commit('SET_EDIT_DATA', response.data)
    }
  },
  destroy(context, id) {
    return serviceTicket.destroy(id)
  },
  previewPdf(context, data) {
    return serviceTicket.previewPdf(data)
  },
  async getFormViewModel(context, { params, index }) {
    let response = await serviceTicket.formViewModel({ params });
    context.commit("SET_VIEW_MODELS", {
      data: response.data,
      index: index
    });
    return Promise.resolve(response);
  },
}
