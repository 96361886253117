import { baseState, baseMutations } from '@/store/mixins'
import itemIncentiveBonus from '@/api/payroll/item-incentive-bonus'

export const state = {
    ...baseState,
    itemCustomRules: []
}
export const getters = {}
export const mutations = {
    ...baseMutations,
    SET_ITEM_CUSTOM_RULES (state, itemCustomRules) {
        state.itemCustomRules = itemCustomRules
    }
}
export const actions = {
    async getItemCustomRule (context, attributes = {}) {
        let response = await itemIncentiveBonus.itemCustomRule({
            params: attributes
        })
        context.commit('SET_ITEM_CUSTOM_RULES', response.data)
    },
    store (context, data) {
        return itemIncentiveBonus.store(data)
    },
    fetch (context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        )
        return itemIncentiveBonus.get({ params }).then(response => {
            context.commit('RECEIVE_RESOURCES', response)
            return Promise.resolve(response)
        })
    },
    update(context, { id, data }) {
        return itemIncentiveBonus.update(id, data);
    },
    destroy (context, id) {
        return itemIncentiveBonus.destroy(id)
    },
}
