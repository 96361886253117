import httpClient from "@/http-client";

export const store = data => {
    return httpClient
        .post("/api/item-allowances", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const get = (options = {}) => {
    return httpClient
        .get("/api/item-allowances", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const update = (id, data) => {
    return httpClient
        .put(`/api/item-allowances/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const destroy = id => {
    return httpClient
      .delete(`/api/item-allowances/${id}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data))
}
export default {store,get,update,destroy}