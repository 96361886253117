import { baseState, baseMutations, Pagination } from "@/store/mixins";
import payrollDetail from "@/api/report/payroll-detail";
import moment from "moment";
import { split, mapKeys, camelCase } from 'lodash'

export const state = {
    ...baseState,
    cycle_year: moment().format("YYYY"),
    cycle_month: moment().format("MM"),
    formViewDatas: [],
    aggregate: {},
    columns: []
};
export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_VIEW_MODELS(state, { data, index }) {
        if (!index) {
            state.formViewDatas = data;
        } else {
            split(index, ",").forEach(el => {
                state.formViewDatas[el] = data[el];
            });
        }
    },
    SET_CYCLE_YEAR(state, cycle_year) {
        state.cycle_year = cycle_year;
    },
    SET_CYCLE_MONTH(state, cycle_month) {
        state.cycle_month = cycle_month;
    },
    RECEIVE_RESOURCES(state, { records, aggregate, columns }) {
        state.aggregate = aggregate
        state.columns = columns
    
        const { data, ...meta } = records
        state.resources = data
        state.pagination = new Pagination(mapKeys(meta, (v, k) => camelCase(k)))
    },
    CLEAR_STATE(state) {
        state.aggregate = {};
        state.columns = [];
    },
};
export const actions = {
    fetch(context, attributes = {}) {
        return payrollDetail
            .view({
                params: Object.assign(
                    {
                        page: context.state.pagination.page || 1,
                        perPage: context.state.pagination.perPage
                    },
                    attributes
                )
            })
            .then(response => {
                context.commit("RECEIVE_RESOURCES", response.data);
                return Promise.resolve(response.data);
            });
    },
    getFormViewData(context, payload) {
        return payrollDetail.formViewModel(payload);
    },
    exportExcel(context, { template_id, ...attributes }) {
        return payrollDetail.exportExcel(template_id, attributes)
      }
};
