import httpClient from '@/http-client'

export const get = (Options = {}) => {
	return httpClient
		.get("/api/repair-product-profiles", Options)
		.then(response => Promise.resolve(response.data))
		.catch(error => Promise.reject(error.response.data))
}

export const find = (id, Options = {}) => {
	return httpClient
		.get(`/api/repair-product-profiles/${id}`, Options)
		.then(response => Promise.resolve(response.data))
		.catch(error => Promise.reject(error.response.data))
}

export const store = data => {
	return httpClient
		.post('/api/repair-product-profiles', data)
		.then(response => Promise.resolve(response.data))
		.catch(error => Promise.reject(error.response.data))
}

export const update = (id, data) => {
	return httpClient
		.put(`/api/repair-product-profiles/${id}`, data)
		.then(response => Promise.resolve(response.data))
		.catch(error => Promise.reject(error.response.data))
}

export const updateRepairStatus = (id, data) => {
	return httpClient
		.put(`/api/repair-product-profiles/update-repair-status/${id}`, data)
		.then(response => Promise.resolve(response.data))
		.catch(error => Promise.reject(error.response.data))
}

export const destroy = id => {
	return httpClient
		.delete(`/api/repair-product-profiles/${id}`)
		.then(response => Promise.resolve(response.data))
		.catch(error => Promise.reject(error.response.data))
}

export const formViewModel = (Options = {}) => {
	return httpClient
		.get(`/api/repair-product-profiles/form-view-models`, Options)
		.then(response => Promise.resolve(response.data))
		.catch(error => Promise.reject(error.response.data))
}

export default {
	get,
	find,
	store,
	updateRepairStatus,
	update,
	destroy,
	formViewModel,
}