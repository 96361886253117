import httpClient from "@/http-client";

export const get = (options = {}) => {
    return httpClient
        .get("/api/loan-repayments", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/loan-repayments", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const destroy = (id, data) => {
    return httpClient
        .put(`/api/loan-repayments/${id}/delete`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const employee = (Options = {}) => {
    return httpClient
        .get("/api/loan-repayments/employees", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const confirm = data => {
    return httpClient
        .post("/api/loan-repayments/confirm", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const loanDisbursement = (Options = {}) => {
    return httpClient
        .get("/api/loan-repayments/loan-disbursements", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const repayment = (Options = {}) => {
    return httpClient
        .get("/api/loan-repayments/repayment-items", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const getDeleted = (options = {}) => {
    return httpClient
        .get("/api/loan-repayments/get-deleted", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export default {
    get,
    store,
    destroy,
    employee,
    loanDisbursement,
    confirm,
    repayment,
    getDeleted
};
