<template>
    <input
        @focus="forcusInput"
        type="text"
        v-bind="$attrs"
        :value="displayCurrency"
        v-if="!displayFormat"
    />
    <input
        type="number"
        ref="input_num"
        v-bind="$attrs"
        v-else
        :value="value"
        @blur="loseForcus"
        @input="onInput"
        @keypress.enter="onKeyPressEnter"
    />
</template>

<script>
export default {
    name: "currencyInput",
    props: {
        value: {
            type: Number,
            default: 0
        },
        currency: {
            type: String,
            default: "$"
        },
        precision: {
            type: Number,
            default: 2
        }
    },
    data() {
        return {
            displayFormat: false
        };
    },
    computed: {
        displayCurrency() {
            const fromAmount = new Intl.NumberFormat("en-US", {
                minimumFractionDigits: this.precision,
                maximumFractionDigits: this.precision
            }).format(this.value);

            return this.currency + " " + fromAmount;
        }
    },
    methods: {
        forcusInput() {
            this.displayFormat = true;
            this.$nextTick(() => {
                this.$refs.input_num.focus();
            });
        },
        loseForcus() {
            this.displayFormat = false;
        },
        onInput(event) {
            let value = event.target.value;
            this.$emit("input", value ? parseFloat(value) : 0);
        },
        onKeyPressEnter() {
            this.$emit("keyPressEnter");
        }
    }
};
</script>
