import httpClient from '@/http-client'

export const view = data => {
    return httpClient
        .post('/api/reports/nssf-reports', data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const orgChartStructure = (Option = {}) => {
    return httpClient
        .get('/api/reports/nssf-reports/org-chart-structures', Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const employee = id => {
    return httpClient
        .get(`/api/reports/nssf-reports/employees/${id}`, Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}

export const formViewModel = (options = {}) => {
    return httpClient
        .get('/api/reports/nssf-reports/form-view-models', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export default { orgChartStructure, formViewModel, view, employee }
