import httpClient from "@/http-client";

export const view = (options = {}) => {
    return httpClient
        .get("/api/reports/payroll-details", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const formViewModel = (options = {}) => {
    return httpClient
        .get("/api/reports/payroll-details/form-view-models", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const exportExcel = (templateId, attributes = {}) => {
    return httpClient
        .post(
            "/api/reports/payroll-details/export",
            { template_id: templateId, ...attributes },
            { responseType: "arraybuffer" }
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data));
};
export default { formViewModel, view, exportExcel };
