import httpClient from "@/http-client";

export const get = (Options = {}) => {
    return httpClient
        .get("/api/organizational-structures", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/organizational-structures", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return httpClient
        .put(`/api/organizational-structures/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const destroy = id => {
    return httpClient
        .delete(`/api/organizational-structures/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const company = (Option = {}) => {
    return httpClient
        .get("/api/organizational-structures/companies", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const levelCounter = (Option = {}) => {
    return httpClient
        .get("/api/organizational-structures/levelCountes", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const parentStructure = (Option = {}) => {
    return httpClient
        .get("/api/organizational-structures/parentStructures", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export default {
    get,
    store,
    update,
    destroy,
    company,
    levelCounter,
    parentStructure
};
