import { baseState, baseMutations } from '@/store/mixins'
import monthlyOvertime from '@/api/payroll/monthly-overtime'

export const state = {
    ...baseState,
    employeeProfiles: []
}
export const getters = {}

export const mutations = {
    ...baseMutations,
    SET_EMPLOYEE_PROFILES (state, employeeProfiles) {
        state.employeeProfiles = employeeProfiles
    }
}
export const actions = {
    async getEmployeeProfile (context, attributes = {}) {
        let response = await monthlyOvertime.employeeProfile({
            params: attributes
        })
        context.commit('SET_EMPLOYEE_PROFILES', response.data)
    },
    store (context, data) {
        return monthlyOvertime.store(data)
    },
    fetch (context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage
            },
            attributes
        )
        return monthlyOvertime.get({ params }).then(response => {
            context.commit('RECEIVE_RESOURCES', response)
            return Promise.resolve(response)
        })
    }
}
