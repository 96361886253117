import httpClient from '@/http-client'

export const employee = data => {
    return httpClient
        .get('/api/reports/leave-reports/employees', data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const leaveReport = (options = {}) => {
    return httpClient
        .get('/api/reports/leave-reports/leave-reports', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const exportExcel = params => {
    return httpClient
        .post('/api/reports/leave-reports/excel', params, {
            responseType: 'arraybuffer'
        })
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export default {
    employee,
    leaveReport,
    exportExcel
}
