import { baseState, baseMutations } from '@/store/mixins'
import monthlyDeduction from '@/api//payroll/monthly-deduction'

export const state = {
    ...baseState,
    employeeProfiles: [],
    payItemDeductions: [],
    serchEmployee: '',
    employees: []
}
export const getters = {}

export const mutations = {
    ...baseMutations,
    SET_EMPLOYEE_PROFILES(state, employeeProfiles) {
        state.employeeProfiles = employeeProfiles
    },
    SET_PAY_ITEM_DEDUCTIONS(state, payItemDeductions) {
        state.payItemDeductions = payItemDeductions
    }
}
export const actions = {
    async getEmployeeProfile(context, attributes = {}) {
        let response = await monthlyDeduction.employeeProfile({
            params: attributes
        })
        context.commit('SET_EMPLOYEE_PROFILES', response.data)
    },

    async getPayItemDeduction(context, attributes = {}) {
        let response = await monthlyDeduction.payItemDeduction({
            params: attributes
        })
        context.commit('SET_PAY_ITEM_DEDUCTIONS', response.data)
    },
    async getLeaveReocrd(context, attributes = {}) {
        return await monthlyDeduction.leaveRecord({
            params: attributes
        })
    },
    getInstallment(context, attributes = {}) {
        return monthlyDeduction.installment({
            params: attributes
        })
    },
    getInstallmentDeduction(context, attributes = {}) {
        return monthlyDeduction.installmentDeduction({
            params: attributes
        })
    },
    store(context, data) {
        return monthlyDeduction.store(data)
    },
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        )
        return monthlyDeduction.get({ params }).then(response => {
            context.commit('RECEIVE_RESOURCES', response)
            return Promise.resolve(response)
        })
    },
    update(context, { id, data }) {
        return monthlyDeduction.update(id, data)
    },
    destroy(context, id) {
        return monthlyDeduction.destroy(id)
    },
    importUnpaidLeave(context, data) {
        return monthlyDeduction.importUnpaidLeave(data)
    },
    importInstallment(context, data) {
        return monthlyDeduction.importInstallment(data)
    },
    clearInstallment(context, data) {
        return monthlyDeduction.clearInstallment(data)
    }
}
