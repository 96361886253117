import httpClient from "@/http-client";

export const get = (Options = {}) => {
    return httpClient
        .get("/api/customer-loan-purposes", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/customer-loan-purposes", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return httpClient
        .put(`/api/customer-loan-purposes/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};


export const destroy = id => {
    return httpClient
        .delete(`/api/customer-loan-purposes/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const loanSector = (Option = {}) => {
    return httpClient
        .get("/api/customer-loan-purposes/loan-sectors", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export default { get, store, update, destroy, loanSector };
