import loanDisbursement from "@/api/credit-operation/loan-disbursement";
import { baseState, baseMutations } from "@/store/mixins";

import { join } from "lodash";

export const state = {
    ...baseState,
    customers: [],
    employees: [],
    currencies: [],
    loanTypes: [],
    repaymentMethods: []
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_CUSTOMERS(state, data) {
        state.customers = data;
    },
    SET_EMPLOYEES(state, data) {
        state.employees = data;
    },
    SET_LOAN_TYPES(state, data) {
        state.loanTypes = data;
    },
    SET_CURRENCIES(state, data) {
        state.currencies = data;
    },
    SET_REPAYMENT_METHODS(state, data) {
        state.repaymentMethods = data;
    },
    CLEAR_RESOURCES(state) {
        state.loanTypes = [];
        state.currencies = [];
        state.customers = [];
        state.employees = [];
        state.repaymentMethods = [];
    }
};

export const actions = {
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search,
                    date_between: join(attributes.dateRange, ","),
                    approve_status: join(attributes.approve_status_id, ",")
                }
            },
            attributes
        );
        return loanDisbursement.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },

    store(context, data) {
        return loanDisbursement.store(data);
    },

    async find(context, id) {
        let response = await loanDisbursement.find(id);
        context.commit("SET_EDIT_DATA", response.data);
    },

    update(context, { id, data }) {
        return loanDisbursement.update(id, data);
    },

    destroy(context, { id, data }) {
        return loanDisbursement.destroy(id, data);
    },
    async getLoanType(context, attributes = {}) {
        let response = await loanDisbursement.loanType({ params: attributes });
        context.commit("SET_LOAN_TYPES", response.data);
    },
    async getCurrency(context, attributes = {}) {
        let response = await loanDisbursement.currency({ params: attributes });
        context.commit("SET_CURRENCIES", response.data);
    },
    async getRepaymentMethod(context, attributes = {}) {
        let response = await loanDisbursement.repaymentMethod({
            params: attributes
        });
        context.commit("SET_REPAYMENT_METHODS", response.data);
    },
    async getEmployees(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: attributes.search,
                    status_id: 1
                }
            },
            attributes
        );
        let response = await loanDisbursement.employee({ params });
        context.commit("SET_EMPLOYEES", response.data);
    },
    async getCustomer(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: attributes.search
                }
            },
            attributes
        );
        let response = await loanDisbursement.customer({ params });
        context.commit("SET_CUSTOMERS", response.data);
    },
    previewPdf(context, attributes = {}) {
        return loanDisbursement.previewPdf({
            params: Object.assign(
                {
                    template: localStorage.getItem("langCode")
                },
                attributes
            )
        });
    },
    exportExcel(context, payload = {}) {
        return loanDisbursement.exportExcel(
            Object.assign(
                {
                    template: localStorage.getItem("langCode")
                },
                payload
            )
        );
    }
};
