import httpClient from "@/http-client";

export const store = data => {
    return httpClient
        .post("/api/payroll-cycle-dates", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const get = (options = {}) => {
    return httpClient
        .get("/api/payroll-cycle-dates", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const destroy = id => {
    return httpClient
        .delete(`/api/payroll-cycle-dates/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const update = (id, data) => {
    return httpClient
        .put(`/api/payroll-cycle-dates/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export default {store,get,destroy,update};
