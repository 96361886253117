import httpClient from '@/http-client'

export const get = (options = {}) => {
    return httpClient
        .get('/api/tax-rangs', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
};
export const taxRangeMain = (Option = {}) => {
    return httpClient
        .get('/api/tax-rangs/tax-range-mains', Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
};
export const store = data => {
    return httpClient
        .post("/api/tax-rangs", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export default {get,store,taxRangeMain}