export default [
    {
        path: '/admin/report/basic-salary-historys',
        name: 'basic-salary-history',
        meta: { requiresAuth: true },
        component: () =>
            import('@/pages/report/payroll/basic-salary-history.vue')
    },
    {
        path: '/admin/report/nssf-reports',
        name: 'nssf-reports',
        component: () => import('@/pages/report/payroll/nssf-report.vue')
    },
    {
        path: '/admin/report/payslip',
        name: 'payslip',
        component: () => import('@/pages/report/payroll/Payslip/payslip-report.vue')
    },
    {
        path: '/admin/report/payroll-details',
        name: 'payroll-detail',
        meta: { requiresAuth: true },
        component: () => import('@/pages/report/payroll/payroll-detail.vue')
    },
    {
        path: '/admin/report/summary-report-pension-fund-and-insurances',
        name: 'summary-report-pension-fund-and-insurance',
        meta: { requiresAuth: true },
        component: () => import('@/pages/report/payroll/summaryPensionAndInsurance/summary-report-pension-fund-and-insurance.vue')
    }
]
