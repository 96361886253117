import httpClient from "@/http-client";

export const view = data => {
    return httpClient
        .post("/api/reports/basic-salary-histories", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const orgChartStructure = (Option = {}) => {
    return httpClient
        .get("/api/reports/basic-salary-histories/org-chart-structures", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const employee = id => {
    return httpClient
        .get(`/api/reports/basic-salary-histories/employees/${id}`, Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const formViewModel = (options = {}) => {
    return httpClient
        .get("/api/reports/basic-salary-histories/form-view-models", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
}

export const exportRawData = attributes => {
    return httpClient
        .post(
            '/api/reports/basic-salary-histories/excel',
            attributes
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}

export default { view, employee, orgChartStructure, formViewModel, exportRawData };
