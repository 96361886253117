export default [
    {
      path: '/admin/customer-loan-requests',
      name: 'customer-loan-request',
      meta: { requiresAuth: true },
      component: () => import('@/pages/credit-operation/customer-loan-request/index.vue')
    },
    {
      path: '/admin/customer-loan-requests/create',
      name: 'customer-loan-request-create',
      meta: { requiresAuth: true },
      component: () => import('@/pages/credit-operation/customer-loan-request/create.vue')
    },
    {
      path: '/admin/customer-loan-requests/:id/edit',
      name: 'customer-loan-request-edit',
      meta: { requiresAuth: true },
      component: () => import('@/pages/credit-operation/customer-loan-request/edit.vue')
    },
    {
      path: '/admin/customer-loan-requests/:id/approve',
      name: 'customer-loan-request-approve',
      meta: { requiresAuth: true },
      component: () => import('@/pages/credit-operation/customer-loan-request/approve.vue')
    },
    {
      path: '/admin/customer-loan-requests/:id/make-disbursement',
      name: 'customer-loan-request-make-disbursement',
      meta: { requiresAuth: true },
      component: () => import('@/pages/credit-operation/customer-loan-request/make-disbursement.vue')
    }
  ]
