import httpClient from '@/http-client'

export const get = (Options = {}) => {
  return httpClient
    .get('/api/coa-categories', Options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const store = data => {
  return httpClient
    .post('/api/coa-categories', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const update = (id, data) => {
  return httpClient
    .put(`/api/coa-categories/${id}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const changeIsBspl = (id, data) => {
  return httpClient
      .put(`/api/coa-categories/change-is-bspl/${id}`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data));
};

export default { get, store, update, changeIsBspl }
