import httpClient from "@/http-client";

export const itemCustomRule = (Option = {}) => {
    return httpClient
        .get('/api/item-incentive-bonus/item-custom-rules', Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
};
export const store = data => {
    return httpClient
        .post('/api/item-incentive-bonus', data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
};
export const get = (options = {}) => {
    return httpClient
        .get('/api/item-incentive-bonus', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
};
export const update = (id, data) => {
    return httpClient
        .put(`/api/item-incentive-bonus/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
};
export const destroy = id => {
    return httpClient
        .delete(`/api/item-incentive-bonus/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export default {itemCustomRule,store,get,update,destroy}