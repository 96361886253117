import httpClient from '@/http-client'

export const get = (options = {}) => {
    return httpClient
        .get('/api/monthly-overtimes', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}

export const employeeProfile = (Option = {}) => {
    return httpClient
        .get('/api/monthly-overtimes/employee-profiles', Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const store = data => {
    return httpClient
        .post('/api/monthly-overtimes', data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export default { employeeProfile, store, get }
