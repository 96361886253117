import httpClient from '@/http-client'

export const get = (options = {}) => {
  return httpClient
    .get('/api/monthly-incentive-bonus', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const store = data => {
  return httpClient
    .post('/api/monthly-incentive-bonus', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const update = (id, data) => {
  return httpClient
    .put(`/api/monthly-incentive-bonus/${id}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const destroy = id => {
  return httpClient
    .delete(`/api/monthly-incentive-bonus/${id}`)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data));
}

export const formViewModel = (options = {}) => {
  return httpClient
    .get("/api/monthly-incentive-bonus/form-view-models", options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data));
}
export const importExcel = data => {
  return httpClient
    .post("/api/monthly-incentive-bonus/import-excels", data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data));
}
export const exportExcel = attributes => {
  return httpClient
    .post(
      "/api/monthly-incentive-bonus/export", attributes,
      { responseType: "arraybuffer" }
    )
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error.response.data));
};
export default { get, store, update, destroy, formViewModel, importExcel, exportExcel }
