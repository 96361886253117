import httpClient from '@/http-client'

export const get = (Options = {}) => {
  return httpClient
    .get('/api/product-profiles', Options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const find = (id, Options = {}) => {
  return httpClient
    .get(`/api/product-profiles/${id}`, Options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const store = data => {
  return httpClient
    .post('/api/product-profiles', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const update = (id, data) => {
  return httpClient
    .put(`/api/product-profiles/${id}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const destroy = id => {
  return httpClient
    .delete(`/api/product-profiles/${id}`)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const branch = (Options = {}) => {
  return httpClient
    .get('/api/product-profiles/branches', Options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const mode = (Options = {}) => {
  return httpClient
    .get('/api/product-profiles/modes', Options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const recipe = (Options = {}) => {
  return httpClient
    .get('/api/product-profiles/recipes', Options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const type = (Options = {}) => {
  return httpClient
    .get('/api/product-profiles/types', Options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const category = (Options = {}) => {
  return httpClient
    .get('/api/product-profiles/categories', Options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const uomCategory = (Options = {}) => {
  return httpClient
    .get('/api/product-profiles/uom-categories', Options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const salecode = data => {
  return httpClient
    .post('/api/product-profiles/sale-codes', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const barcode = categoryId => {
  return httpClient
    .get(`/api/product-profiles/barcodes/${categoryId}`)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const supplier = () => {
  return httpClient
    .get('/api/product-profiles/suppliers')
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const group = () => {
  return httpClient
    .get('/api/product-profiles/groups')
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const upload = (id, data) => {
  return httpClient
    .post(`/api/product-profiles/${id}/images`, data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const saleAvailable = id => {
  return httpClient
    .put(`/api/product-profiles/sale-availables/${id}`, [])
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const calculateStock = id => {
  return httpClient
    .put(`/api/product-profiles/calculate-stocks/${id}`, [])
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const getSerial = (id, Options = {}) => {
  return httpClient
    .get(`/api/product-profiles/${id}/serials`, Options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const getAllSerial = (Options = {}) => {
  return httpClient
    .get('/api/product-profiles/all-serials', Options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const serialStatus = (Options = {}) => {
  return httpClient
    .get('/api/product-profiles/serial-status', Options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const getSerialHistory = serialNo => {
  return httpClient
    .get(`/api/product-profiles/${serialNo}/serial-histories`)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const setImei = data => {
  return httpClient
    .post('/api/product-profiles/set-imei', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const employee = (Options = {}) => {
  return httpClient
    .get('/api/product-profiles/employees', Options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const allowChangUomCat = productId => {
  return httpClient
    .get(`/api/product-profiles/${productId}/allow-change-uom`)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const serialToExcel = data => {
  return httpClient
    .post('/api/product-profiles/serial-to-excels', data, {
      responseType: 'arraybuffer'
    })
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error.response.data))
}

export const warehouseAssigned = (Options = {}) => {
  return httpClient
    .get('/api/product-profiles/warehouse/assigned', Options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const formViewModel = (Options = {}) => {
  return httpClient
    .get('/api/product-profiles/form-view-models', Options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}
export const exportExcel = attributes => {
  return httpClient
    .post('/api/product-profiles/export', attributes, {
      responseType: 'arraybuffer'
    })
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error.response.data))
}
export default {
  get,
  find,
  store,
  update,
  destroy,
  branch,
  mode,
  type,
  category,
  uomCategory,
  salecode,
  barcode,
  supplier,
  upload,
  recipe,
  saleAvailable,
  calculateStock,
  group,
  getSerial,
  getSerialHistory,
  getAllSerial,
  serialStatus,
  setImei,
  employee,
  allowChangUomCat,
  serialToExcel,
  warehouseAssigned,
  formViewModel,
  exportExcel
}
