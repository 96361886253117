export default [
    {
      path: '/admin/employees',
      name: 'employee',
      meta: { requiresAuth: true },
      component: () => import('@/pages/human-resource/employee/index.vue')
    },
    {
      path: '/admin/employees/create',
      name: 'employee-create',
      meta: { requiresAuth: true },
      component: () => import('@/pages/human-resource/employee/create.vue')
    },
    {
      path: '/admin/employees/:id/edit',
      name: 'employee-edit',
      meta: { requiresAuth: true },
      component: () => import('@/pages/human-resource/employee/edit.vue')
    }
  ]
