import httpClient from '@/http-client'

export const get = (Options = {}) => {
	return httpClient
		.get("/api/service-quotations", Options)
		.then(response => Promise.resolve(response.data))
		.catch(error => Promise.reject(error.response.data))
}

export const find = (id, Options = {}) => {
	return httpClient
		.get(`/api/service-quotations/${id}`, Options)
		.then(response => Promise.resolve(response.data))
		.catch(error => Promise.reject(error.response.data))
}

export const store = data => {
	return httpClient
		.post('/api/service-quotations', data)
		.then(response => Promise.resolve(response.data))
		.catch(error => Promise.reject(error.response.data))
}

export const update = (id, data) => {
	return httpClient
		.put(`/api/service-quotations/${id}`, data)
		.then(response => Promise.resolve(response.data))
		.catch(error => Promise.reject(error.response.data))
}

export const destroy = id => {
	return httpClient
		.delete(`/api/service-quotations/${id}`)
		.then(response => Promise.resolve(response.data))
		.catch(error => Promise.reject(error.response.data))
}

export const previewPdf = id => {
	return httpClient
		.get(`/api/service-quotations/${id}/preview-pdf`)
		.then(response => Promise.resolve(response.data))
		.catch(error => Promise.reject(error.response.data))
}

export const formViewModel = (Options = {}) => {
	return httpClient
		.get(`/api/service-quotations/form-view-models`, Options)
		.then(response => Promise.resolve(response.data))
		.catch(error => Promise.reject(error.response.data))
}

export default {
	get,
	find,
	store,
	update,
	destroy,
	previewPdf,
	formViewModel,
}