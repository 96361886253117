import systemSetting from "@/api/setting/system";

import { baseState, baseMutations } from "@/store/mixins";

export const state = {
    ...baseState,
    generals: [],
    repaymentItemPriorities: [],
    overduePenalties: [],
};
export const getters = {};
export const mutations = {
    ...baseMutations,
    SET_GENERALS(state, data) {
        state.generals = data;
    },
    SET_REPAYMENT_ITEM_PRIORITIES(state, data) {
        state.repaymentItemPriorities = data;
    },
    SET_OVERDULE_PAYMENTS(state, data) {
        state.overduePenalties = data;
    },
};
export const actions = {
    store(context, data) {
        return systemSetting.store(data);
    },
    async getGeneral(context, attributes = {}) {
        let response = await systemSetting.general({ params: attributes });
        context.commit("SET_GENERALS", ...response.data);
    },
    async getRepaymentItemPriority(context, attributes = {}) {
        let response = await systemSetting.repaymentItemPriority({ params: attributes });
        context.commit("SET_REPAYMENT_ITEM_PRIORITIES", response.data);
    },
    async getoverduePenalty(context, attributes = {}) {
        let response = await systemSetting.overduePenalty({ params: attributes });
        context.commit("SET_OVERDULE_PAYMENTS", response.data);
    }
};
