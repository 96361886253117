import httpClient from "@/http-client";

export const get = (Options = {}) => {
    return httpClient
        .get("/api/loan-currencies", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/loan-currencies", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return httpClient
        .put(`/api/loan-currencies/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const loanType = (Option = {}) => {
    return httpClient
        .get("/api/loan-currencies/loan-types", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const currency = (Option = {}) => {
    return httpClient
        .get("/api/loan-currencies/currencies", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export default { get, store, update, loanType, currency };
