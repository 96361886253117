export default [
	{
		path: '/admin/service-quotations',
		name: 'service-quotation',
		meta: { requiresAuth: true },
		component: () => import('@/pages/customer-service/service-quotation/index.vue')
	},
	{
		path: '/admin/service-quotations/create',
		name: 'service-quotation-create',
		meta: { requiresAuth: true },
		component: () => import('@/pages/customer-service/service-quotation/create.vue')
	},
	{
		path: '/admin/service-quotations/:id/edit',
		name: 'service-quotation-edit',
		meta: { requiresAuth: true },
		component: () => import('@/pages/customer-service/service-quotation/edit.vue')
	},
]