import Vue from "vue";
import Vuex from "vuex";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

Vue.use(Vuex);

import modules from "./modules";

const store = new Vuex.Store({
    state: {
        authUser: null,
        accessToken: null,
        isLockScreen: false
    },
    getters: {
        authenticated: state => state.accessToken !== null,
        accessToken: state => state.accessToken,
        formatNumber: state => (value = "", currency_id = 2) => {
            let decimal = state.authUser.sys_setting[0].decimal_digit;
            if (value) {
                let decimalDigit = (value / 1)
                    .toFixed(decimal)
                    .replace(",", ".");
                let val = value
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                if (currency_id == 2) {
                    if (
                        decimalDigit.substring(decimalDigit.indexOf(".") + 1) ==
                        0
                    ) {
                        return val;
                    }
                    return (
                        val.split(".")[0] +
                        decimalDigit.substring(decimalDigit.indexOf("."))
                    );
                }
                if (value.toString().indexOf(".") >= 0) {
                    // input value with decimal
                    return (
                        val.split(".")[0] +
                        decimalDigit.substring(decimalDigit.indexOf("."))
                    );
                }
                return val + decimalDigit.substring(decimalDigit.indexOf("."));
            }
            return "-";
        },
        formatCurrencyWithCode: (state, getters) => (value, currency) => {
            if (state) {
                //
            }
            if (!currency) {
                if (value) {
                    return value
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
                return "-";
            }
            return (
                getters.formatNumber(value, currency.currency_id) +
                " " +
                currency.currency_code
            );
        },
        formatCurrencyWithSign: (state, getters) => (value, currency) => {
            if (!currency) {
                if (value) {
                    return value
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
                return "-";
            }
            if (state) {
                //
            }
            return (
                getters.formatNumber(value, currency.currency_id) +
                " " +
                currency.currency_sign
            );
        },
        signBycurrencyId: state => currency_id => {
            if (currency_id == 1) {
                //usd
                return "$";
            }
            if (currency_id == 2) {
                //khr
                return "៛";
            }
            if (currency_id == 3) {
                //bath
                return "B";
            }
            if (state) {
                //
            }
        }
    },
    mutations: {
        setAuthUser(state, authUser) {
            if (authUser) {
                state.authUser = Object.assign(
                    {},
                    {
                        user: authUser.user,
                        permissions: authUser.permissions,
                        sys_setting: authUser.sys_setting,
                        company: authUser.company
                    }
                );
                localStorage.setItem(
                    "authUser",
                    JSON.stringify(state.authUser)
                );
            } else {
                state.authUser = null;
                localStorage.clear();
            }
            state.accessToken = VueCookies.get("token");
        },
        setIsLock(state, value) {
            state.isLockScreen = value;
            localStorage.setItem("!z_dy#@_____00111$%!@", value);
        }
    },
    actions: {
        init({ commit }) {
            const authUser = JSON.parse(localStorage.getItem("authUser"));
            if (authUser) {
                commit("setAuthUser", authUser);
            }
            commit(
                "setIsLock",
                parseInt(localStorage.getItem("!z_dy#@_____00111$%!@"))
            );
        }
    },
    modules
});

store.dispatch("init");

export default store;
