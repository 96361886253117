import { baseState, baseMutations } from "@/store/mixins";

import payrollCycle from "@/api/human-resource/payroll-cycle";

export const state = {
    ...baseState
};
export const getters={};

export const mutations ={
    ...baseMutations
}
export const actions = {
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return payrollCycle.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },
    store(context, data) {
        return payrollCycle.store(data);
    },
    destroy(context, id) {
        return payrollCycle.destroy(id);
    },
    update(context, { id, data }) {
        return payrollCycle.update(id, data);
    },
}
