<template>
    <div
        class="app app-header-fixed app-sidebar-fixed"
        v-if="authenticated && !appOptions.appEmpty"
        v-bind:class="{
            'app-sidebar-minified': appOptions.appSidebarMinified,
            'app-content-full-height': appOptions.appContentFullHeight,
            'app-without-sidebar': appOptions.appSidebarNone,
            'app-with-end-sidebar': appOptions.appSidebarEnd,
            'app-with-two-sidebar': appOptions.appSidebarTwo,
            'app-with-wide-sidebar': appOptions.appSidebarWide,
            'app-with-light-sidebar': appOptions.appSidebarLight,
            'app-sidebar-mobile-toggled': appOptions.appSidebarMobileToggled,
            'app-sidebar-end-toggled': appOptions.appSidebarEndToggled,
            'app-sidebar-end-collapsed': !appOptions.appSidebarEndToggled,
            'app-sidebar-end-mobile-toggled':
                appOptions.appSidebarEndMobileToggled,
            'app-header-fixed': !appOptions.appHeaderNone,
            'app-without-header': appOptions.appHeaderNone,
            'app-with-top-menu': appOptions.appTopMenu,
            'has-scroll': appOptions.appBodyScrollTop
        }"
    >
        <Header />
        <TopMenu v-if="appOptions.appTopMenu" />
        <Sidebar v-if="!appOptions.appSidebarNone" />
        <SidebarRight v-if="appOptions.appSidebarTwo" />
        <div
            id="content"
            class="app-content"
            v-bind:class="appOptions.appContentClass"
        >
            <router-view></router-view>
            <vue-ins-progress-bar></vue-ins-progress-bar>
        </div>
        <notifications />
        <ts-lock-screen v-show="isLock" @reset="resetTimer"></ts-lock-screen>
        <Modal
            v-model="showForm"
            draggable
            sticky
            scrollable
            :mask="false"
            fullscreen
            :z-index="1022"
            :title="$t('branch.branchExpireContract')"
        >
            <ts-branch-expire-contract />
        </Modal>
    </div>
    <div v-else-if="authenticated && appOptions.pageEmpty">
        <router-view></router-view>
        <vue-ins-progress-bar></vue-ins-progress-bar>
        <ts-lock-screen v-show="isLock" @reset="resetTimer"></ts-lock-screen>
    </div>
    <div class="h-100" v-else>
        <router-view></router-view>
        <vue-ins-progress-bar></vue-ins-progress-bar>
    </div>
</template>

<script>
import Sidebar from "./components/sidebar/Sidebar.vue";
import SidebarRight from "./components/sidebar-right/SidebarRight.vue";
import Header from "./components/header/Header.vue";
import TopMenu from "./components/top-menu/TopMenu.vue";
import AppOptions from "./config/AppOptions.vue";
import VueCookies from "vue-cookies";
import { mapState } from "vuex";

export default {
    name: "app",
    components: {
        Sidebar,
        SidebarRight,
        Header,
        TopMenu
    },
    data() {
        return {
            appOptions: AppOptions,
            lock: false,
            showForm: false,
            lockTimer: null,
            events: [
                "click",
                "mousemove",
                "mousedown",
                "scroll",
                "keypress",
                "load"
            ]
        };
    },
    computed: {
        ...mapState('corporate/branchExpireContract', [
            'resources',
            'pagination'
        ]),
        authenticated() {
            return VueCookies.get("token") !== null;
        },
        isLock() {
            return this.$store.state.isLockScreen;
        },
        idleTimeOut() {
            return (
                (this.$store.state.authUser.sys_setting.length > 0
                    ? parseInt(
                          this.$store.state.authUser.sys_setting[0]
                              .idle_time_out
                      )
                    : 5) *
                60 *
                1000
            );
        }
    },
    methods: {
        handleScroll: function() {
            AppOptions.appBodyScrollTop = window.scrollY;
        },
        setTimer() {
            if (this.authenticated) {
                this.lockTimer = setTimeout(() => {
                    this.$store.commit("setIsLock", 1);
                    localStorage.setItem("!z_dy#@_____00111$%!@", 1);
                }, this.idleTimeOut);
            }
        },
        resetTimer() {
            clearTimeout(this.lockTimer);
            this.setTimer();
        },
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch(
                    "corporate/branchExpireContract/fetch", attributes)
                .then(() => {
                    if (this.resources.length == 0) {
                        this.showForm = false;
                    } else {
                        this.showForm = true;
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                    // this.notice({ type: "error", text: error.message });
                });
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "BRANCH",
                desc: not.text
            });
        }
    },
    mounted() {
        this.$insProgress.finish();
        this.events.forEach(event => {
            window.addEventListener(event, this.resetTimer);
        });

        this.setTimer();
    },
    created() {
        AppOptions.appBodyScrollTop = window.scrollY;

        window.addEventListener("scroll", this.handleScroll);

        this.$insProgress.start();

        this.$router.beforeEach((to, from, next) => {
            this.$insProgress.start();
            next();
        });
        this.$router.afterEach(() => {
            this.$insProgress.finish();
        });
        this.$Notice.config({
            top: 50,
            duration: 5
        });
        this.$Message.config({
            top: 50,
            duration: 5
        });
        this.fetchData()
    }
};
</script>
