import category from '@/api/product/product-category'

import { baseState, baseMutations } from '@/store/mixins'
import { isEmpty } from "lodash";

export const state = {
  ...baseState
}

export const getters = {}

export const mutations = {
  ...baseMutations
}

export const actions = {
  fetch(context, attrubutes = {}) {
    let params = Object.assign(
      {
        page: context.state.pagination.page,
        perPage: context.state.pagination.perPage,
        filter: {
          search: context.state.search
        }
      },
      attrubutes
    )
    return category.get({ params }).then(response => {
      context.commit('RECEIVE_RESOURCES', response)
      return Promise.resolve(response)
    })
  },
  store(context, data) {
    return category.store(data)
  },
  update(context, { id, data }) {
    return category.update(id, data)
  },
  async find(context, id) {
    if (isEmpty(context.state.edit_data)) {
      let response = await category.find(id)
      context.commit('SET_EDIT_DATA', response.data)
    }
  },
  destroy(context, id) {
    return category.destroy(id)
  },
}