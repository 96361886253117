import OvertimeRuleByShift from '@/api/payroll/overtime-rule-by-shift'
import { baseState, baseMutations } from '@/store/mixins'

export const state = {
  ...baseState,
  shiftWorkingHours: [],
  overtimeItems: []
}

export const getters = {}

export const mutations = {
  ...baseMutations,

  RECEIVE_SHIFT_WORKING_HOURS(state, shiftWorkingHours) {
    state.shiftWorkingHours = shiftWorkingHours
  },

  RECEIVE_OVERTIME_ITEMS(state, overtimeItems) {
    state.overtimeItems = overtimeItems
  }
}

export const actions = {
  fetch(context, attributes = {}) {
    let params = Object.assign(
      {
        page: context.state.pagination.page,
        perPage: context.state.pagination.perPage,
        filter: {
          search: context.state.search
        }
      },
      attributes
    )
    return OvertimeRuleByShift.get({ params }).then(response => {
      context.commit("RECEIVE_RESOURCES", response);
      return Promise.resolve(response);
    });
  },

  store(context, data) {
    return OvertimeRuleByShift.store(data)
  },

  update(context, { id, data }) {
    return OvertimeRuleByShift.update(id, data)
  },

  destroy(context, id) {
    return OvertimeRuleByShift.destroy(id).then(response => {
      let resource = context.state.resources.find(r => r.record_id == id)

      if (resource) {
        context.state.resources.splice(context.state.resources.indexOf(resource), 1)
      }

      return response
    })
  },

  getShiftWorkingHours(context) {
    return OvertimeRuleByShift.getShiftWorkingHours().then(response => {
      context.commit('RECEIVE_SHIFT_WORKING_HOURS', response.data)
      return Promise.resolve(response)
    })
  },
  getOvertimeItems(context) {
    return OvertimeRuleByShift.getOvertimeItems().then(response => {
      context.commit('RECEIVE_OVERTIME_ITEMS', response.data)
      return Promise.resolve(response)
    })
  },
}
