import company from "@/api/corporate/company";
import { baseState, baseMutations } from "@/store/mixins";

import { isEmpty } from "lodash";

export const state = {
    ...baseState
};

export const getters = {};

export const mutations = {
    ...baseMutations
};

export const actions = {
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return company.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },

    store(context, data) {
        return company.store(data);
    },

    async find(context, id) {
        if (isEmpty(context.state.edit_data)) {
            let response = await company.find(id);
            context.commit("SET_EDIT_DATA", response.data);
        }
    },

    update(context, { id, data }) {
        return company.update(id, data);
    },

    destroy(context, id) {
        return company.destroy(id);
    },

    uploadLogo(context, data) {
        return company.uploadLogo(data);
    },

    getLogo(context, company_id) {
        return company.getLogo(company_id);
    }
};
