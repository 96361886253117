import attendanceDevice from "@/api/human-resource/attendance-device";
import { baseState, baseMutations } from "@/store/mixins";

export const state = {
    ...baseState,
    deviceTypes: []
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_DEVICETYPE(state, data) {
        state.deviceTypes = data;
    }
};

export const actions = {
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return attendanceDevice.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },

    store(context, data) {
        return attendanceDevice.store(data);
    },

    update(context, { id, data }) {
        return attendanceDevice.update(id, data);
    },

    destroy(context, id) {
        return attendanceDevice.destroy(id);
    },
    async getDeviceType(context, attributes = {}) {
        let response = await attendanceDevice.deviceType({
            params: attributes
        });
        context.commit("SET_DEVICETYPE", response.data);
    }
};
