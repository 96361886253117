import httpClient from "@/http-client";

export const get = (Options = {}) => {
    return httpClient
        .get("/api/reports/attendance-details", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const orgChartStructure = (Option = {}) => {
    return httpClient
        .get("/api/reports/attendance-details/org-chart-structures", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const employee = id => {
    return httpClient
        .get(`/api/reports/attendance-details/employees/${id}`, Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export default { get, employee, orgChartStructure };
