import holiday from "@/api/human-resource/holiday";
import { baseState, baseMutations } from "@/store/mixins";

export const state = {
    ...baseState
};

export const getters = {};

export const mutations = {
    ...baseMutations
};

export const actions = {
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return holiday.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },

    store(context, data) {
        return holiday.store(data);
    },

    generate(context, data) {
        return holiday.generate(data);
    },
    update(context, { id, data }) {
        return holiday.update(id, data);
    }
};
